<input type="text" (input)="keyPressed($event)" [(ngModel)]="search_str" placeholder="Buscar Artículo" autocomplete="off" class="form-control w-100" #item_search>
<div style="overflow:visible;max-height:10px;position:relative;z-index:3">
	<div class="pointer border" style="background-color:white" *ngIf="item_info_list.length">
		<div *ngFor="let item_info of item_info_list;let i=index" (click)="onItemSelected(item_info)" class="search_item border-bottom" [ngClass]="{'active':selected_index==i}">
			<div *ngIf="item_info.item.image_id || item_info?.category?.image_id" class="d-inline-block ratio ratio-1x1" [style]="{'background-image':'url('+rest.getImagePath(item_info.item.image_id,item_info?.category?.image_id)+')','background-size':'contain',width:'40px','vertical-align':'middle','background-repeat':'no-repeat','background-position':'center center'}">
				<img src="/assets/2px_transparent.png" width=40 height="40">
			</div>
			<span *ngIf="!(item_info.item.image_id || item_info?.category?.image_id)">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="40px" height="40px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M13.409 2.512a3.75 3.75 0 0 0-2.818 0l-2.19.888l9.592 3.73l3.374-1.303a1.747 1.747 0 0 0-.46-.275l-7.498-3.04zM22 7.19l-9.25 3.574v10.923c.224-.045.444-.112.659-.199l7.498-3.04A1.75 1.75 0 0 0 22 16.826V7.19zM11.25 21.687V10.764L2 7.19v9.636a1.75 1.75 0 0 0 1.093 1.622l7.498 3.04c.215.087.435.154.659.2zM2.633 5.827L12 9.446l3.917-1.514l-9.543-3.71l-3.281 1.33c-.17.069-.324.162-.46.275z" fill="currentColor"/></g></svg>
			</span>
			<span *ngIf="item_info.category">{{item_info.category.name | titlecase }} - </span>
			{{item_info.item.name}}
			<ng-container *ngIf="store_id">
				<span *ngIf="item_info.stock_qty > 0" class="fw-bold">
					[{{ item_info.stock_qty }}]
				</span>
				<span *ngIf="!item_info.stock_qty || item_info.stock_qty < 0.001" style="color:red!important" class="fw-bold">
					[0]
				</span>
			</ng-container>
		</div>
	</div>
</div>
