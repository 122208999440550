import { Component, OnInit } from '@angular/core';
import {ParamMap} from '@angular/router';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {Utils} from 'src/app/classes/Utils';
import {Store, Table} from 'src/app/models/RestModels';
import {SearchObject} from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

interface CTable extends Table
{
	store:Store;
}

@Component({
	selector: 'app-list-table',
	templateUrl: './list-table.component.html',
	styleUrls: ['./list-table.component.css']
})
export class ListTableComponent extends BaseComponent
{
	table_search: SearchObject<Table> = this.getEmptySearch();
	table_list: CTable[] = [];
	store_list: Store[] = [];
	store_dict:Record<string,Store> = {};

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((param_map:ParamMap)=>
			{
				let fields = ['name', 'description', 'capacity','store_id','created', 'updated','attended_by_user_id'];
				this.table_search = this.getSearch(param_map, fields)

				return forkJoin
				({
					tables: this.rest.table.search(this.table_search),
					stores: this.rest.getStores(false)
				});
			})
		)
		.subscribe((response)=>
		{
			this.store_list = response.stores.data;
			this.table_list = response.tables.data.map(t=>{
				let store = this.store_list.find(s=>t.store_id == s.id ) as Store;
				return {...t, store };
			});

		},(error)=>{
			this.showError( error );
		})
	}
}
