import { Component,OnInit } from '@angular/core';
import {ParamMap} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { BankMovementInfo, OrderInfo, PaymentInfo} from 'src/app/models/models';
import { Bank_Movement_Order, Payment } from 'src/app/models/RestModels';
import { SearchObject,RestResponse } from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

interface Bank_Movement_Order_Custom extends Bank_Movement_Order
{
	sum: number;
	prev_balance: number;
	balance: number;
	order_info:OrderInfo;
	payment_info:PaymentInfo;
	payment_type: string;
}

@Component({
	selector: 'app-view-payment',
	templateUrl: './view-payment.component.html',
	styleUrls: ['./view-payment.component.css']
})
export class ViewPaymentComponent extends BaseComponent implements OnInit
{
	payment_info:PaymentInfo | null = null;
	orders_by_id:Record<number,OrderInfo> = {};
	payment_info_list:PaymentInfo[] = [];
	order_info:OrderInfo | null = null;
	sums:Bank_Movement_Order_Custom[] = [];
	balance:number = 0;
	all_partial_payments:number = 0;
	orders_total:number = 0;

	legend:Record<string,string> = {
		'CASH-MXN':'Efectivo',
		'CASH-USD':'Dolares',
		'CREDIT_CARD-MXN':'Tarjeta Débito',
		'DEBIT_CARD-MXN':'Tarjeta Crédito',
		'CHECK-MXN':'Cheque',
		'TRANSFER-MXN':'Transferencia',
	};

	print: boolean = false;
	order_info_list: OrderInfo[] = [];

	ngOnInit(): void
	{
		//Esto se ejecuta 1 sola vez

		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((param_map:ParamMap)=>
			{
				this.payment_info = null;
				this.orders_by_id = {};

				this.print = param_map.get('print') != null;

				return this.rest.payment_info.get( param_map.get('payment_id') )
			}),
			mergeMap((payment_info)=>
			{
				this.payment_info = payment_info;
				this.all_partial_payments = 0;

				let order_ids:number[] = payment_info.movements.reduce((prev:number[],bm)=>
				{
					let order_ids = bm.bank_movement_orders.reduce((prev:number[],bo:Bank_Movement_Order)=>{
						prev.push( bo.order_id );
						return prev;
					},[]);
					prev.push(...order_ids);
					return prev;
				}, []);


				let	payment_search:SearchObject<Payment> = this.getEmptySearch();
				payment_search.limit = 99999;
				payment_search.le.id = payment_info.payment.id;
				payment_search.sort_order = ['id_ASC'];
				payment_search.search_extra= { order_id: order_ids.join(',') };

				return forkJoin
				({
					payments: this.rest.payment_info.search( payment_search ) as Observable<RestResponse<PaymentInfo>>,
					order_info: this.rest.order_info.search({csv:{id: order_ids },limit:order_ids.length} )
				})
			})
		)
		.subscribe((response)=>
		{
			this.payment_info_list = response.payments.data as PaymentInfo[];
			//console.log('Payment info list', this.payment_info_list);
			this.order_info_list = response.order_info.data;
			console.log( response.order_info.data );

			let descripcion:Bank_Movement_Order_Custom[] = [];

			let sum = 0;

			//in order to get the total of pending payments, we set the payment_info to the last payment
			let sum_by_order_id:Map<number,number> = new Map();
			//also we need to get the total of each order, so we can calculate the balance at the end
			let total_by_order_id:Map<number,number> = new Map();

			this.payment_info_list.forEach((payment_info)=>
			{
				if( payment_info.payment.id > this.payment_info.payment.id )
					return;

				payment_info.movements.forEach((movement)=>
				{
					movement.bank_movement_orders.forEach((bmo)=>
					{
						let payment_type:string = movement.bank_movement.transaction_type;
						//console.log('Buscando', bmo.order_id );
						let order_info = this.order_info_list.find(oi=>oi.order.id == bmo.order_id);

						if( order_info )
						{
							//get the total of the order
							let total = total_by_order_id.get(order_info.order.id ) || 0;
							total = order_info.order.total - order_info.order.discount;
							total_by_order_id.set(order_info.order.id, total );

							//get the sum of the previous payments
							let sum = sum_by_order_id.get(order_info.order.id ) || 0;
							console.log('sum_by_order_id', sum_by_order_id.get(order_info.order.id ))
							let prev_balance = order_info.order.total-(order_info.order.discount+sum);


							sum += bmo.amount;
							let balance = order_info.order.total -(order_info.order.discount + sum);

							let bmoc:Bank_Movement_Order_Custom = {
								...bmo,sum,balance,order_info,payment_info,prev_balance,payment_type
							};
							sum_by_order_id.set(order_info.order.id, sum );

							descripcion.push(bmoc);
						}
						else
						{
							console.log('No se encontro orden',bmo.order_id);
						}
					});
				});
			});
			console.log('final sum_by_order_id', sum_by_order_id);
			console.log('Descripcion',descripcion);
			this.sums = descripcion;
			//all the partial payments are in the map sum_by_order_id, so we just need to sum them
			this.all_partial_payments = Array.from(sum_by_order_id.values()).reduce((prev:number,curr:number)=>prev+curr,0);
			//we also need to sum the total of each order
			this.orders_total = Array.from(total_by_order_id.values()).reduce((prev:number,curr:number)=>prev+curr,0);
			console.log('all_partial_payments', this.all_partial_payments);
			if( this.print )
			{
				setTimeout(()=>{
					window.print();
					window.close();
				},200);
			}
		},(error)=>this.showError(error));
	}

	justPrint()
	{
		let print_func = ()=>window.print();
		this.rest.hideMenu();
		setTimeout( print_func, 700 );
	}

	back()
	{
		this.location.back();
	}
}
