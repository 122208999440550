<div class="container-fluid">
	<h1 class="my-3 text-capitalize">{{attribute.id ? 'Actualizar':'Agregar'}}Attributos</h1>
	<form ngNativeValidate (submit)="save()">
		<div class="card p-3">
			<div class="row p-3">
				<div class="col-6 col-md-4">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="attribute.name" class="form-control" required>
				</div>
			</div>
			<div class="row p-3">
				<div class="col-12 text-right">
					<button type="submit"  [disabled]="is_loading" class="btn btn-primary">Guardar</button>
				</div>
			</div>
		</div>
	</form>
</div>
