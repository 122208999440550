import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { Attribute } from 'src/app/models/RestModels';
import { Rest, SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

@Component
({
	selector: 'app-list-attribute',
	templateUrl: './list-attribute.component.html',
	styleUrls: ['./list-attribute.component.css']
})

export class ListAttributeComponent extends BaseComponent implements OnInit
{
	attribute_search:SearchObject<Attribute> = this.getEmptySearch();
	attribute_list: Attribute[] = [];
	rest_attribute:Rest<Attribute,Attribute> = this.rest.initRest('attribute');

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((param_map:ParamMap)=>
			{
				let fields = ['name', 'description', 'capacity','store_id','created', 'updated','attended_by_user_id'];
				this.attribute_search = this.getSearch(param_map, fields)

				return this.rest_attribute.search(this.attribute_search);
			})
		)
		.subscribe
		({
			next:(response)=>
			{
				this.attribute_list = response.data;
				this.setPages( this.attribute_search.page, response.total );
			},
			error:(error)=>
			{
				this.showError( error )
			}
		})
	}

}
