import { Component, OnInit } from '@angular/core';
import {forkJoin, from, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { Coordinates, Utils } from 'src/app/classes/Utils';
import {OrderItemInfo} from 'src/app/models/models';
import {Platform_Address} from 'src/app/models/PlatformModels';
import {Order, Store, Table, User} from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-c-cart',
	templateUrl: './c-cart.component.html',
	styleUrls: ['./c-cart.component.css']
})
export class CCartComponent extends BaseComponent implements OnInit
{
	order_item_info_list:OrderItemInfo[] = [];
	platform_address_list:Platform_Address[] = [];
	platform_address:Platform_Address| null = null;
	store:Store | null = null;
	show_delivery_address:boolean = false;
	price_by_option_group:Record<number,number> = {};
	show_select_store:boolean = false;
	store_list:Store[] = [];
	selected_store_id:number | null;
	selected_table_id:number | null;
	table:Table | null;

	user:User | null;

	total:number = 0;
	subtotal:number = 0;
	tax:number = 0;

	ngOnInit(): void
	{
		this.sink = this.route.paramMap.pipe
		(
			mergeMap((response)=>
			{
				this.is_loading = true;
				let p:Promise<GeolocationPosition|null> = new Promise((success,error)=>{
					let options = {
						enableHighAccuracy: true,
						timeout: 10000,
						maximumAge: 30000
					};

					let e_fun = (e)=>{ success(null ) }
					navigator.geolocation.getCurrentPosition( success, e_fun ,options);
				});

				return forkJoin({
					response_table: response.has('table_id') ? this.rest.table.get( response.get('table_id') ) : of( null ),
					db_table: from( this.rest.getSelectedTable() ),
					location: from( p )
				})
			}),
			mergeMap((response)=>
			{
				let table:Table|null = response.response_table || response.db_table;

				return forkJoin
				({
					address: this.rest.platform_address.search({limit:9999}),
					order_items_info: this.rest.getCartItems(),
					store: this.rest.getStores(),
					user: this.rest.user.get('me'),
					table: of( table ),
					location: of( response.location )
				});
			})
		).subscribe((response)=>
		{
			console.log('Init again');
			this.platform_address_list = response.address.data;
			this.store_list = response.store.data;

			if( response.table )
			{
				if( response.location == null )
				{
					let p = this.rest.local_preferences.name;
					this.showError('Para pedir en la mesa debes encontrarte en la sucursal');
					this.router.navigate(['/geolocation-error']);
					this.is_loading = false;
					return;
				}

				let store = response.store.data.find(s=>s.id == response.table.store_id );
				if( store )
				{
					let store_coords:Coordinates = {
						lat: store.lat,
						lng: store.lng
					};
					let user_coord:Coordinates = {
						lat: response.location.coords.latitude,
						lng: response.location.coords.longitude
					};

					let distance = Utils.distanceTo(store_coords, user_coord );

					if( distance < 100 )
					{
						this.table = response.table;
						this.store = store;
					}
					else
					{
						let p = this.rest.local_preferences.name;
						this.showError('Para pedir en la mesa debes encontrarte en la sucursal '+p+' '+store.name);
						this.router.navigate(['/geolocation-error']);
						return;
					}
				}
				else
				{
					this.store = null;
					this.table = null;
				}
			}
			this.is_loading = false;

			if( this.table )
			{
				this.selected_store_id = this.table.store_id;

				this.store = this.store_list.find((store)=>{
					return store.id == this.selected_store_id;
				}) as Store;
			}
			else if( !this.selected_store_id && this.store_list.length == 1 )
			{
				this.selected_store_id = this.store_list[0].id;
				this.store = this.store_list[0];
				this.rest.setClientStoreId( this.selected_store_id );
				console.log("Store id is by default", this.selected_store_id);
			}
			else
			{
				console.log('Store id is set', this.selected_store_id );
				this.store = this.store_list.find((store)=>store.id == this.selected_store_id );
			}

			this.user = response.user;

			if( this.platform_address_list.length )
				this.platform_address = this.platform_address_list[0];

			this.order_item_info_list = response.order_items_info;
			this.updatePrices();
			this.is_loading = false;
		})
	}

	removeCartItem(order_item_info:OrderItemInfo)
	{
		this.rest.removeCartItem(order_item_info).then((response)=>
		{
			this.showSuccess('Se eliminó el elemento del carrito');
			let index = this.order_item_info_list.findIndex(i=> i==order_item_info);

			if( index > -1 )
				this.order_item_info_list.splice(index,1);
		})
		.catch((error)=>
		{
			this.showError(error);
		})
	}

	showAddNewAddress()
	{
		this.show_delivery_address = true;
	}

	incremente(order_item_info:OrderItemInfo)
	{
		order_item_info.order_item.qty++;
		this.updatePrices()
	}

	decrement(order_item_info:OrderItemInfo)
	{
		if( order_item_info.order_item.qty > 1 )
			order_item_info.order_item.qty--;

		this.updatePrices()
	}

	updatePrices()
	{
		let f_list = this.rest.normalizarOrderItems(this.order_item_info_list);
		f_list.forEach(i=>this.rest.updateOrderItemPrice(i.order_item,this.store?.tax_percent || 16, 0 ));

		this.rest.updateCart(f_list)
		.then(()=>
		{
			console.log('Exito');
		},(error)=>console.log(error));

		for(let i in this.price_by_option_group)
		{
			this.price_by_option_group[ i ] = 0;
		}

		this.order_item_info_list.forEach(i=>this.price_by_option_group[i.order_item.item_group] += i.order_item.total );

		this.total = this.order_item_info_list.reduce((p,c)=>p+c.order_item.total,0);
		this.subtotal = this.order_item_info_list.reduce((p,c)=>p+c.order_item.subtotal,0);
		this.tax = this.order_item_info_list.reduce((p,c)=>p+c.order_item.tax,0);
	}

	createOrder()
	{
		if( this.platform_address == null )
		{
			this.show_delivery_address = true;
			return;
		}

		if( this.is_loading )
			return;

		if( this.store == null )
		{

		}

		let user = this.user;
		//let address:Platform_Address= this.platform_address as Platform_Address;
		let store:Store = this.store as Store;
		let version_created = this.rest.getVersion();

		let order:Order = {
			tag: '',
			//zipcode: this.address.zipcode,
			address: this.platform_address.address,
			cancellation_reason: '',
			cancellation_timestamp: null,
			cancelled_by_user_id: null,
			client_name: user.name,
			client_user_id: user.id,
			closed_timestamp: null,
			created: new Date(),
			currency_id: '$',
			delivery_schedule: null,
			discount: 0,
			discount_calculated: 0,
			external_id: null,
			facturacion_timestamp: null,
			lat: this.platform_address.lat,
			lng: this.platform_address.lng,
			period_id: null,
			price_type_id: user.price_type_id,
			sat_domicilio_fiscal_receptor: '',
			sat_exchange_rate: 0,
			sat_factura_id: null,
			sat_ieps: 0,
			sat_isr: 0,
			sat_regimen_capital_receptor: '',
			sat_regimen_fiscal_receptor:'',
			service_type: this.table ? 'IN_PLACE':'TOGO',
			shipping_address_id: null,
			status: 'PENDING',
			store_id: store.id,
			subtotal: this.subtotal,
			sync_id: this.rest.getSyncId(),
			system_activated: null,
			table_id: this.table?.id,
			tax: this.tax,
			tax_percent: store.tax_percent,
			total: this.total,
			version_created,
			version_updated: version_created
		};

		this.is_loading = true;

		this.subs.sink = this.rest.user.get('me').pipe
		(
			mergeMap((response)=>
			{
				return this.rest.order_info.create({order,items:this.order_item_info_list})
			})
		)
		.subscribe((response)=>
		{
			this.is_loading = false;
			this.showSuccess('Tu orden fue registrada con exito');
			this.rest.sendNotification('order', response.id );
			this.rest.cleanCart().then(()=>
			{

			});

			console.log('Orden is', response.order.id );
			this.router.navigate(['/c-view-order', response.order.id]);
			this.is_loading = false;
		},(error)=>this.showError(error));
	}

	changeAddress()
	{
		this.show_delivery_address = true;
	}

	onSelectDeliveryAddress(platform_address:Platform_Address| null )
	{
		this.rest.removeTable().then(()=>{
			this.table = null;
		});

		this.platform_address = platform_address;
		this.show_delivery_address = false;
	}

	showStoreSelector()
	{
		this.subs.sink = this.rest.getStores(true)
		.subscribe((response)=>
		{
			this.store_list = response.data;
			if( response.data.length == 1)
			{
				this.store = response.data[0];
				this.rest.setClientStoreId(this.store.id);
			}
			else
			{
				this.show_select_store = true;
			}
		},(error)=>this.showError(error));
	}

	onStoreSelected()
	{
		this.rest.setClientStoreId(this.selected_store_id);
		this.show_select_store = false;
		this.router.navigate(['/c-select-category']);
	}
}
