import { Component } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { bufferCount, concatMap, mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';
import { Utils } from 'src/app/classes/Utils';
import { AttachmentInfo, ItemInfo, OrderInfo } from 'src/app/models/models';
import { Category, Category_Tree, Item, Order, Price, Price_Type, Stock_Alert, Stock_Record, Store } from 'src/app/models/RestModels';
import { RestResponse, SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

import	{createOrder} from './payload';


@Component({
	selector: 'app-weird',
	templateUrl: './weird.component.html',
	styleUrls: ['./weird.component.css']
})
export class WeirdComponent extends BaseComponent
{
	qty: number = 1;
	store_id:number | string = '';
	store_list:Store[] = [];
	file: File | null = null;
	file_prices: File | null = null;
	facturacion_code: string= '';
	file_stock: File | null = null;
	file_maxmin: File | null = null;
	test_objs: { a: number; b: number; c: number; hello: string; world: string; extra_fields?:Record<string,any>}[]= [];
	minimun_file: File | null;
	update_items_file: File | null;
	file_production_area_item_file: File | null = null;
	address_file: File | null = null;
	users_file: File | null = null;

	ngOnInit(): void
	{
		this.subs.sink = this.rest.updates.subscribe((response)=>
		{
			console.log('LLego subscription',response);
		});

		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap(()=>
			{
				return this.rest.store.search({limit:99999,sort_order:['name_ASC']});
			})
		)
		.subscribe( storeResponse =>
		{
			this.store_list = storeResponse.data;
		},(error)=>this.showError(error));
	}

	sendNotification(evt:Event)
	{
		this.is_loading = true;

		let formdata = new FormData(evt.target as HTMLFormElement);
		let type = formdata.get('type') as string;
		let id = formdata.get('id') as string;

		this.rest.sendNotification(type, id);
	}

	createTables(evt:Event)
	{
		this.is_loading = true;
		this.subs.sink = this.rest
			.update('createTables',{qty:this.qty, store_id:this.store_id})
			.subscribe(()=>
			{
				this.showSuccess('Mesas creadas');
				let f = evt.target as HTMLFormElement;
				f.reset();
			});
	}

	updateOffline()
	{
		this.rest.forceSyncOfflineItems();
	}

	onFileChanged(event:any)
	{
		if (event.target.files.length)
		{
			this.file = event.target.files[0];
		}
	}

	createItems(evt:Event)
	{
		this.is_loading = true;

		if( this.file == null )
			return;


		//let headers = ['categoria','prodoserv','subcategoria','producto','unidad','costo','codigo'];
		let headers = ['producto','categoria','subcategoria','costo','prodoserv','unidad','codigo'];

		ExcelUtils.xlsx2json( this.file,headers)
		.then((json:Record<string,any>[])=>
		{
			let main_categories = new Map<string,string>();
			let subcategories = new Map<string,string>();

			json.forEach((row:Record<string,string>)=>
			{
				let spaces = /\s+/g;
				console.log( row.categoria );
				let cat = row.categoria ? row.categoria.replaceAll(spaces,' ').toLowerCase().trim() : '';
				let sub = '';
				if( row.subcategoria )
				{
					sub = row.subcategoria.replaceAll(spaces,' ').toLowerCase().trim();
				}

				if( cat )
				{
					main_categories.set(cat,row['prodoserv']);
				}

				if( sub )
				{
					main_categories.set(sub,row['prodoserv']);
					subcategories.set(sub,cat);
				}
			});

			this.subs.sink = this.rest
			.category
			.search({limit:9999999})
			.pipe
			(
				mergeMap((response)=>
				{
					let existing_categories = new Map<string,Category>();
					let spaces = /\s+/g;

					for(let c of response.data)
					{
						let cname = c.name.replaceAll(spaces,' ').toLowerCase().trim();
						existing_categories.set( cname , c );
					}
					return forkJoin
					({
						existing_categories:of( existing_categories ),
						categories: of( response.data )
					})
				})
				,mergeMap((response)=>
				{
					let existing_categories = response.existing_categories
					let new_categories:Array<string> = [];

					let a = Array.from( main_categories.keys() );

					for(let category_name of a)
					{
						if( !existing_categories.has( category_name ) )
						{
							new_categories.push( category_name );
						}
					}

					let to_create = new_categories.map((name)=>{
						let code = main_categories.get(name);

						let ec = GetEmpty.category();
						ec.name = name;
						ec.default_clave_prod_serv = code;
						return ec;
					});

					return forkJoin
					({
						new_categories: to_create.length > 0 ? this.rest.category.batchCreate( to_create ): of([]),
						existing_categories: of( existing_categories ),
						category_tree: this.rest.category_tree.search({limit:-1})
					})
				})
				,mergeMap((response)=>
				{
					let existing_categories = response.existing_categories;

					response.
					new_categories.forEach((category)=>
					{
						existing_categories.set(category.name, category);
					});

					let items = json.map((row)=>
					{

						let spaces = /\s+/g;

						let category_name = !row.subcategoria ? '' : row.subcategoria.replaceAll(spaces,' ').trim().toLowerCase();
						let category:Category |null = response.existing_categories.get(category_name);

						if( category === null )
						{
							category = response.new_categories.find(c=>c.name == category_name) || null;
						}

						let value = parseFloat((''+row.costo).replaceAll(/\s/g,'').replaceAll('$','')) || 0;

						let item_info = GetEmpty.item_info();
						item_info.item.name = row.producto;
						item_info.item.reference_price = Number.isNaN( value ) ? null : value;
						item_info.item.on_sale = 'YES';
						item_info.item.clave_sat = row.prodoserv??null;
						item_info.item.unidad_medida_sat_id = row['clave unidad']??'H87';
						item_info.item.measurement_unit = row.unidad??'';
						item_info.item.partial_sale = 'NO';
						item_info.item.json_tags = [];
						item_info.item.return_action = 'RETURN_TO_STOCK';
						item_info.item.code = row.codigo;

						if( category )
							item_info.item.category_id = category.id;


						return item_info;
					});

					//for(let cname in response.new_categories)
					//{
					//	if( subcategories.has( cname ) )
					//	{
					//		let paren_name = subcategories.get( cname );
					//		if( existing_categories.has(parent_name ) )
					//		{
					//			let

					//		}
					//	}
					//}
					//

					let category_tree_2_add:Category_Tree[] = [];

					subcategories.forEach((value,key)=>
					{
						let subcategory = existing_categories.get( key );
						let category = existing_categories.get( value );

						if( subcategory && category )
						{
							let fCt = (ct:Category_Tree)=>{
								return ct.child_category_id == subcategory.id && ct.parent_category_id == category.id;
							};

							let category_tree = response.category_tree.data.find( fCt );
							let already_added = category_tree_2_add.find( fCt );

							if( category_tree === undefined && already_added === undefined )
							{
								category_tree_2_add.push
								({
									id: 0,
									parent_category_id: category.id,
									child_category_id: subcategory.id,
									path: '',
									created_by_user_id: this.rest.current_user.id,
									updated_by_user_id: this.rest.current_user.id,
									depth: 1,
									created: new Date(),
									updated: new Date(),
								});
							}
						}
						else
						{
							console.log( 'No existe Cat o fue agregado :'+category+' Sub:'+subcategory );
						}
					});
					return forkJoin
					({
						category_tree: category_tree_2_add.length == 0
							? of([])
							: this.rest.category_tree.batchUpdate( category_tree_2_add ),
						items: of( items )
					})
				}),
				mergeMap((response)=>
				{
					let chunks = [];
					let current_array:ItemInfo[] = [];
					let counter = 0;

					for(let item_info of response.items )
					{
						current_array.push( item_info );

						if( (++counter % 100) == 0 )
						{
							chunks.push( current_array );
							current_array = [];
						}
					}


					return from(chunks).pipe
					(
						concatMap((items)=>
						{
							return this.rest.item_info.batchCreate( items );
						})
					);
				})
			)
			.subscribe((response)=>
			{
				this.showSuccess('Se agregaron '+response.length+' Elementos' );
			}
			,(error)=>
			{
				this.showError(error);
			});

			/*
			let items:ItemInfo[] = json.map((i)=>{
				let item = {
					name: i.name,
					extra_name: i.extra_name as string,
					on_sale: i.on_sale,
					note_required: i.note_required,
					availability_type: i.availability_type,
					description: i.description,
					status: 'ACTIVE',
					reference_price: i.reference_price,
					clave_sat: i.clave_sat,
					unidad_medida_sat_id: i.unidad_medida_sat_id,
				} as Item;

				return {
					item,
					category:
					prices: [] as Price[],
					records: [] as Stock_Record[],
					options: [] as ItemOptionInfo[],
					exceptions:[] as Item_Exception[],
				}
			})
			//Filter json then upload
			this.subs.sink	= this.rest.item.batchUpdate(items as Partial<Item>[])
			.subscribe((result)=>
			{
				this.searchNoForceReload(this.item_search);
				this.showSuccess('Imported succesfully '+result.length+' items');
			},(error)=>this.showError(error));
			*/
		});
	}

	getClaveSat(s:string):string
	{
		//if( s.includes('kilo') )
		//	return 'KGM';

		return 'H87';
	}

	exportPrices()
	{
		const TOTAL_PAGES = 100;
		let search:SearchObject<Item> = this.getEmptySearch<Item>();
		search.limit = TOTAL_PAGES;

		this.rest.item_info.search( search )
		.pipe
		(
			mergeMap(
				(response)=>{

					let total_pages = Math.ceil( response.total/TOTAL_PAGES );
					let all_searches = [of(response)];


					for(let i=1;i<total_pages;i++)
					{
						let osearches = {...search };
						osearches.page	= i;
						all_searches.push( this.rest.item_info.search( osearches ) );
					}

				return forkJoin( all_searches );
			}),
			mergeMap((response)=>{

				let gr:RestResponse<ItemInfo> = {
					total: response[0].total,
					data: [],
				};

				for(let r of response)
				{
					gr.data.push( ...r.data );
				}
				return of( gr );
			}),
			mergeMap((response)=>{
				return forkJoin({
					item_info: of( response ),
					price_type: this.rest.price_type.search({limit:9999999}),
					price_list: this.rest.price_list.search({limit: 9999999})
				})
			})
		)
		.subscribe((response)=>
		{
			let r:Record<string,any>[] = [];

			let pl_map = new Map();
			let pt_map = new Map();

			for(let pl of response.price_list.data)
			{
				pl_map.set(pl.id, pl );
			}

			for(let pt of response.price_type.data)
			{
				pt_map.set(pt.id, pt );
			}

			for(let ii of response.item_info.data)
			{
				for(let p of ii.prices )
				{
					let pt = pt_map.get( p.price_type_id );
					let pl = pl_map.get( p.price_list_id );
					r.push({
						item_id: ii.item.id,
						category_id: ii.item.category_id,
						category_name: ii?.category?.name || '',
						item_name: ii.item.name,
						price_list_name: pl.name,
						price_type_name: pt.name,
						price_type_id : pt.id,
						price_list_id : pl.id,
						price: p.price,
						price_percent: p.percent,
					});
				}
			}

			/*
			let headers = [
				"item_id",
				"category_id",
				"category_name",
				"item_name",
				"price_list_name",
				"price_type_name",
				"price_type_id",
				"price_list_id",
				"price",
				"price_percent",
			];
			*/

			let headers = [ 'producto','categoria','subcategoria','costo','prodoserv','unidad','codigo'];

			ExcelUtils.array2xlsx(r,'Precios.xlsx',headers);
		});
	}

	setPriceFile(evt:any)
	{
		let target = evt.target as HTMLInputElement;

		if (target.files.length)
		{
			this.file_prices = target.files[0];
		}
		else
		{
			this.file_prices = null;
		}
	}

	onUpdateItemsFile(evt:Event)
	{
		let target = evt.target as HTMLInputElement;

		if (target.files.length)
		{
			this.update_items_file = target.files[0];
		}
		else
		{
			this.update_items_file = null;
		}
	}

	updateItems(evt:Event)
	{
		evt.preventDefault();
		evt.stopPropagation();

		let headers = [
			"id", "applicable_tax", "background", "commission", "commission_type", "commission_currency_id",
			"product_id", "commanda_type_id", "category_id", "currency_id", "image_id", "brand_id", "provider_user_id",
			"reference_currency_id", "code", "has_serial_number", "ieps_type", "ieps_value", "name", "extra_name", "form_id", "note_required",
			"on_sale", "availability_type", "status", "sort_weight", "description", "reference_price", "return_action",
			"clave_sat", "measurement_unit", "unidad_medida_sat_id", "image_style", "json_tags", "text_color",
			"text_style", "shadow_color", "partial_sale",
		];

		let items = [];

		ExcelUtils.xlsx2json( this.update_items_file, headers )
		.then((rows)=>
		{
			let items = [];

			for(let r of rows)
			{
				let ru = {};

				for(let h of headers)
				{
					if(h in r)
					{
						let val = r[h];

						if(typeof(val) == 'string' && val.trim() == '' )
							continue;


						if( h == 'json_tags' )
						{
							if( val.trim().indexOf('[') == -1 )
							{
								ru[h] =	val.trim().split(',');
								continue;
								//Dejamos que el siguiente if le haga el parse
							}
							else
							{
								console.log('QUE PEDO');
							}
						}

						if( h.indexOf('json') > -1 )
						{
							try{
								ru[h] = JSON.parse( val );
							}
							catch(e)
							{
								console.log('FALLO EN JSON parse');
								continue;
							}

						}
						else
						{
							ru[h] = val == 'null' ? null : val;
						}
					}
				}

				items.push( ru );
			}

			this.is_loading = true;
			this.subs.sink = this.rest.item
			.batchUpdateJSON( items )
			.subscribe
			({
				next:(response)=>
				{
					this.showSuccess('Articulos actualizados');
				},
				error:(error)=>
				{
					this.showError(error);
				}
			});
		});
	}

	updatePrices(evt:Event)
	{
		evt.preventDefault();
		evt.stopPropagation();

		let headers = [
			"item_id",
			"category_id",
			"category_name",
			"item_name",
			"price_list_name",
			"price_type_name",
			"price_type_id",
			"price_list_id",
			"price",
			"percent",
			"currency_id",
		];

		ExcelUtils.xlsx2json( this.file_prices,	headers )
		.then((response)=>
		{
			let prices:Price[] = [];
			console.log( response );

			for(let x of response )
			{
				console.log( x );

				if( !x.price || !x.percent )
					continue;

				prices.push
				({
					price_type_id: x.price_type_id,
					price_list_id: x.price_list_id,
					price: x.price,
					percent: x.percent,
					item_id: x.item_id,
					currency_id:	x.currency_id,
					created: new Date(),
					updated: new Date()
				});
			}

			this.subs.sink = this.rest.price.batchCreate( prices ).subscribe(()=>{
				this.showSuccess('Se actualizaron los precios');
			},(error)=>{

			});
		});

		//});
	}

	onAttachmentChange(attachmentInfo:AttachmentInfo)
	{
		this.showSuccess('Se subio el archivo con id'+attachmentInfo.attachment.id);
	}

	reverseItemsSortWeight()
	{
		this.subs.sink = this.rest
		.update('reverseItemOrder',{})
		.subscribe((_response)=>
		{
			this.showSuccess('Se cambio el sentido de ordenaimiento de los articulos');
		},(error)=>this.showError(error));
	}
	searchOrders()
	{
		let searchObject:SearchObject<Order> = this.getEmptySearch();
		searchObject.lk.facturacion_code = this.facturacion_code;
		this.search
		this.router.navigate(['/list-order'],{queryParams:{'lk.facturacion_code':this.facturacion_code}});
	}

	uploadImages(evt:any)
	{
		if (evt.target.files.length)
		{
			for(let f of evt.target.files )
			{
				console.log( f.name );
			}

			//this.rest.uploadImage(evt.target.files[0], false, this.max_width, this.max_height).subscribe((imageData) => {
			//	if( this.displayUploadedImageName )
			//		this.image = imageData.id;

			//	this.imageChange.emit( imageData.id );
			//}, error => this.rest.showError(error));
		}
	}

	updateMaxMin(evt:Event)
	{
		evt.preventDefault();
		evt.stopPropagation();

		let headers = [ "item_id", "min", "max", "store_id" ];

		ExcelUtils.xlsx2json( this.file_maxmin,	headers )
		.then((response)=>
		{
			let stock_alert_list:Stock_Alert[] = []
			for(let x of response )
			{
				console.log( x );

				stock_alert_list.push
				({
					id: 0,
					item_id: x.item_id,
					store_id: x.store_id,
					max: x.max || 0,
					min: x.min || null,
					created: new Date(),
					updated: new Date(),
					updated_by_user_id: this.rest.current_user.id,
					created_by_user_id: this.rest.current_user.id
				});
			}

			this.is_loading = true;
			this.subs.sink = this.rest.stock_alert.batchCreate( stock_alert_list )
			.subscribe((response)=>
			{
				this.showSuccess('Se actualizaron las alertas de stock');
			},(error)=>{
				this.showError(error);
			});

		});
	}

	setMaxMinFile(evt:any)
	{
		if (evt.target.files.length)
		{
			this.file_maxmin = evt.target.files[0];
		}
		else
		{
			this.file_maxmin = null;
		}
	}

	updateStock(evt:Event)
	{
		evt.preventDefault();
		evt.stopPropagation();

		let headers = [
			"item_id",
			"store_id",
			"qty",
		];


		//this.subs.sink = this.rest.price_type.search({limit: 99999})
		//.subscribe((response)=>{

		//	let pt_map:Map<number,Price_Type> = new Map();
		//	for(let pt of response.data )
		//	{
		//		pt_map.set( pt.id, pt );
		//	}
		ExcelUtils.xlsx2json( this.file_stock,	headers )
		.then((response)=>
		{
			let prices:Price[] = [];
			console.log( response );

			let stock_records = []
			for(let x of response )
			{
				console.log( x );

				stock_records.push
				({
					item_id: x.item_id,
					store_id:	x.store_id,
					qty: x.qty
				});
			}

			this.subs.sink = this.rest
			.update('adjustStock', {stock_records })
			.subscribe((response)=>
			{
				this.showSuccess('Se actualizaron el inventario');
			},(error)=>{
				this.showError(error);
			});
		});

		//});
	}

	setStockFile(evt: any)
	{
		if (evt.target.files.length)
		{
			this.file_stock = evt.target.files[0];
		}
		else
		{
			this.file_prices = null;
		}

	}

	exportReferences()
	{
		this.is_loading = true;
		const SEARCH_LIMIT = 200;

		let search:SearchObject<Item> = this.getEmptySearch();
		//let search = this.getSearch(param_map,
		//search.search_extra.category_name = this.search_str.trim();
		search.eq.status = 'ACTIVE';
		search.limit = SEARCH_LIMIT;
		search.sort_order = ['category.sort_weight_DESC','sort_weight_DESC','name_ASC'];

		this.subs.sink = this.rest.item_info
		.search( search )
		.pipe
		(
			mergeMap((response)=>
			{
				let observables = [ of(response) ];

				let items_promises:Observable<RestResponse<ItemInfo>>[] = [];
				let pages_needed = Math.ceil( response.total/SEARCH_LIMIT );

				for(let i=1;i<pages_needed;i++)
				{
					let s:SearchObject<Item> = this.getEmptySearch();
					//s.search_extra.category_name = this.search_str.trim();
					s.eq.status = 'ACTIVE';
					s.limit = SEARCH_LIMIT;
					s.page = i;
					s.sort_order = ['category.sort_weight_DESC','sort_weight_DESC','name_ASC'];
					observables.push(	this.rest.item_info.search( s ) );
				}

				console.log('Returning promises', items_promises);
				return forkJoin(observables);
			})
		)
		.subscribe((response)=>
		{
			let items = response.reduce((p,c)=>{
				p.push(...c.data );
				return p;
			},[]);

			console.log('Cantidad de articulos'+items.length);

			let data:Record<string,string|number>[] = [];

			for(let oii of items )
			{
				if( this.store_id )
				{
					let store_record = oii.records.find((r:Stock_Record)=> r.store_id == this.store_id );
					let store = this.store_list.find(s=>s.id == this.store_id );
					this.appendToList(store_record, store, oii, data );
				}
				else
				{
					for(let store of this.store_list)
					{
						let store_record = oii.records.find((r:Stock_Record)=> r.store_id == store.id ) || null;
						this.appendToList(store_record, store, oii, data );
					}
					//this.appendToList(null, this.store_list[0], oii, data );
				}
			}

			let d = new Date();
			let headers = ['Categoría','Artículo','Sucursal','Cantidad', '#Serie','Prioridad','Descripcion', 'Datos Adicionales', 'Codigo','id','image_id'];
			let filename = 'Referencias'+Utils.getMysqlStringFromDate(d).substring(0,10)+'.xlsx';
			ExcelUtils.array2xlsx(data, filename , headers );
		},(error)=>this.showError(error));
	}

	appendToList(store_record:Stock_Record | null ,store:Store,oii:ItemInfo,data:Record<string,string|number>[])
	{
		let sr:Record<string,string|number> = {};

		if( !store_record )
		{
			data.push({
				'Sucursal': store.name,
				'Categoría': oii?.category?.name || '',
				'Artículo': oii.item.name,
				'Cantidad': 0,
				'#Serie' : '',
				'Prioridad': oii.item.sort_weight,
				'Descripcion':'',
				'Datos Adicionales': '',
				'Codigo': oii.item.code,
				'id': oii.item.id,
				'category_id': oii.item.category_id,
				'image_id': oii.item.image_id
			});
			return;
		}

		if( oii.item.has_serial_number == 'NO' )
		{
			data.push({
				'Sucursal': store.name,
				'Categoría': oii?.category?.name || '',
				'Artículo': oii.item.name,
				'Cantidad': store_record.qty,
				'#Serie'	: '',
				'Prioridad': oii.item.sort_weight,
				'Descripcion': '',
				'Datos Adicionales': '',
				'Codigo': oii.item.code,
				'id': oii.item.id,
				'category_id': oii.item.category_id,
				'image_id': oii.item.image_id
			})
			return;
		}

		let serials = oii.serials.filter(si=>si.serial.store_id == store.id );

		if( serials.length )
		{
			for(let s of serials )
			{
				data.push({
					'Sucursal': '',//store.name,
					'Categoría': oii?.category?.name || '',
					'Artículo': oii.item.name,
					'Cantidad': 1,
					'#Serie'	: s.serial.serial_number,
					'Prioridad'	: oii.item.sort_weight,
					'Descripcion':s.serial.description || '',
					'Datos Adicionales': s.serial.additional_data || '',
					'Codigo': oii.item.code || '',
					'id': oii.item.id || '',
					'category_id': oii.item.category_id,
					'image_id': oii.item.image_id
				})
			}
			return;
		}
		//Aqui se extraaña el forelse
		//No habia para la sucursal asi que ponemos 0
		if( serials.length == 0 )
		{
			data.push
			({
				'Sucursal': store.name,
				'Categoría': oii?.category?.name || '',
				'Artículo': oii.item.name,
				'Cantidad': 0,
				'#Serie'	: '',
				'Prioridad': oii.item.sort_weight,
				'Descripcion': '',
				'Datos Adicionales': '',
				'Codigo': oii.item.code || '',
				'id': oii.item.id || '',
				'category_id': oii.item.category_id,
				'image_id': oii.item.image_id
			})
		}
	}

	evalProperties()
	{
		let props = {
			quantity : 'x.a+x.b+x.c',
			multi: 'x.a*x.b*x.c',
			other: 'x.a+x.b*x.c',
			hello_world: 'x.hello+x.world'
		};

		let values = [
			{a:1,b:2,c:3,hello:'hello',world:'worldx'},
			{a:3,b:5,c:6,hello:'hello',world:'worldx'},
			{a:2,b:8,c:3,hello:'Not hello',world:'world2'},
			{a:7,b:20,c:8,hello:'hello',world:'world2'},
			{a:9,b:2,c:3,hello:'hello',world:'world3'},
		];

		this.test_objs = this.propEvaluator(props,values);
	}

	propEvaluator(prop:Record<string,any>,values_array:Record<string,any>[])
	{
		let results = [];
		for(let wl of values_array)
		{
			if(!('extra_fields' in wl) )
			{
				wl.extra_fields = {};
			}

			for(let key in prop)
			{
				let js2 = 'let x= '+JSON.stringify(wl)+';'+prop[key];
				//console.log( js2 );
				//console.log('Eval is ', window.eval(js2 ));
				wl.extra_fields[key] = window.eval( js2 );
				//console.log('key is',key, wl.extra_fields[key] );
			}

			results.push( wl );
		}
		return results;
	}

	onMinimunFileChanged(evt: Event)
	{
		let target = evt.target as HTMLInputElement;
		this.minimun_file = target.files[0];
	}


	updateItemMin(evt:Event)
	{
		evt.preventDefault();
		evt.stopPropagation();

		let headers = [ "item_id", "Articulo", "Minimo", "Sucursal"];

		ExcelUtils.xlsx2json( this.minimun_file, headers )
		.then((rows)=>
		{
			this.subs.sink = this.rest.store.search({ limit: 999999 }).pipe
			(
				mergeMap((store_response)=>
				{
					let map = new Map<string,number>();
					for(let s of store_response.data )
					{
						map.set(s.name.trim(), s.id );
						console.log('Seting '+s.name+' '+s.id );
						console.log('FOOO',map.get(s.name.trim() ));
					}
					return of( map );
				}),
				mergeMap((map:Map<string,number>)=>
				{
					let data:Stock_Alert[] = [];

					for(let r of rows)
					{
						if( !map.has( r.Sucursal.trim() ) )
						{
							throw 'El registro no cuenta con una sucursal valida "'+r.Sucursal+'"';
						}

						let store_id = map.get( r.Sucursal );
						console.log(r.Sucursal+' '+store_id );

						let stock_alert:Stock_Alert = {
							id: 0,
							created: new Date(),
							created_by_user_id: 0,
							item_id: r.item_id,
							max:null,
							min: r.Minimo,
							store_id: store_id,
							updated: new Date(),
							updated_by_user_id: 0,
						};

						data.push( stock_alert );
					}
					return this.rest.stock_alert.batchCreate(data);
				}),
			)
			.subscribe
			({
				next:(_response)=>
				{
					this.showSuccess('Se actualizaron los cantidades minimas');
				},
				error:(error)=>
				{
					this.showError(error);
				}
			});
		});
	}

	onProductionAreaItemFileChanged(evt:Event)
	{
		let target = evt.target as HTMLInputElement;
		this.file_production_area_item_file = target.files[0];
	}

	updateProductionAreaItem(evt:Event)
	{
		evt.preventDefault();
		evt.stopPropagation();

		let headers = [
			"item_id",
			"production_area_id",
		];

		ExcelUtils.xlsx2json( this.file_production_area_item_file,	headers )
		.then((response)=>
		{
			let to_update = response.filter((x:any)=>x.production_area_id && x.item_id);
			console.log( 'To update',to_update );

			if( to_update.length == 0 )
			{
				this.showError('No hay registros para actualizar');
				return;
			}

			let map = new Map<number,Object>();

			response.forEach((x) =>
			{
				map.set(x.production_area_id, x );
			});


			let production_area_id = Array.from( map.keys() );

			this.subs.sink = forkJoin
			({
				rows: of( to_update ),
				production_area_item: this.rest.production_area_item.search
				({
					limit: 9999999,
					csv:{ production_area_id: production_area_id }
				})
			})
			.pipe
			(
				mergeMap((response)=>
				{
					let filter = (row:any)=>{
						return row.production_area_id && row.item_id;
					};

					let to_add = response.rows.filter( filter );
					return this.rest.production_area_item.batchCreate( to_add );
				})
			)
			.subscribe
			({
				next:(_response)=>
				{

					this.showSuccess(`Se actualizaron "${response.length}" Articulos en areas de producción`);
				},
				error:(error)=>
				{
					this.showError(error);
				}
			});
		});
	}

	onAddressFileChanged(event)
	{
		if (event.target.files.length)
		{
			this.address_file = event.target.files[0];
		}
		else
		{
			this.address_file = null;
		}
	}

	updateAddresses(event)
	{
		let headers = [
			"user_id", "name", "email","note",
			"phone", "rfc", "address", "suburb", "city",
			"country", "state", "sat_regimen_capital",
			"sat_regimen_fiscal", "sat_uso_cfdi", "zipcode", "type"
		];

		event.preventDefault();

		let to_update = [];

		ExcelUtils.xlsx2json( this.address_file,	headers )
		.then((response)=>
		{
			console.log('response',response);
			let to_update = response.filter(x=>x.rfc );
			console.log('To update',to_update);

			this.subs.sink = this.rest.address
				.batchUpdate( to_update )
				.subscribe
				({
					next:()=>
					{
						this.showSuccess('Se actualizaron los datos de las direcciones');
					}
					,error:(error)=>
					{
						this.showError(error);
					}
				});
		});
	}

	onUsersFileChanged(event)
	{
		if (event.target.files.length)
			{
				this.users_file = event.target.files[0];
			}
			else
			{
				this.users_file = null;
			}
	}

	createUsersShipping(event)
	{
		let headers = [
			"name", "phone", "email", "address", "city", "state", "zipcode"
		];

		event.preventDefault();

		ExcelUtils.xlsx2json(this.users_file, headers)
			.then((response) =>
			{
				console.log('response', response);

				let users = response.map(usuarios =>
				{
					let u = GetEmpty.user();
					u.name = usuarios.name;
					u.phone = usuarios.phone || "";
					u.email = usuarios.email;
					u.type = "CLIENT";
					u.price_type_id = 1;
					u.store_id = usuarios.store_id;
					return u;
				});

				this.subs.sink = this.rest.user.create(users).subscribe({
					next: (created_users) =>
					{
						this.showSuccess('Se crearon los clientes');

						console.log(response);

						let addresses = [];

						response.forEach((row_excel, index) =>
						{
							let address_str = row_excel.address ? row_excel.address.toString() : "";

							if (address_str.trim() !== "")
							{
							  let address = GetEmpty.address();

							  address.user_id = created_users[index].id;
							  address.name = created_users[index].name;
							  address.address = address_str;
							  address.city = row_excel.city || "";
							  address.email = row_excel.email || "";
							  address.phone = row_excel.phone || "";
							  address.state = row_excel.state || "";
							  address.zipcode = row_excel.zipcode || "";
							  address.status = "ACTIVE";
							  address.type = "SHIPPING";
							  addresses.push(address);
							}
						  });

						from(addresses)
						.pipe(
							bufferCount(500),
							mergeMap((address_batch) => this.rest.address.create(address_batch))
						)
						.subscribe(
						{
							next: () =>
							{
								this.showSuccess("Clientes y direcciones creados correctamente");
							},
							error: (error) =>
							{
								this.showError("Error al insertar direcciones: " + error);
							}
						});
					},
					error: (error) =>
					{
						this.showError("Error al insertar usuarios: " + error);
					}
				});
			});
	}

	createUsersBilling(event)
	{
		let headers = `name,	phone,	email,	address,	rfc,	city,	state,	zipcode,
		sat_razon_social,	store_id,	sat_uso_cfdi,	sat_regimen_fiscal`.split(",").map(i=>i.trim());

		event.preventDefault();

		ExcelUtils.xlsx2json(this.users_file, headers)
			.then((response) =>
			{
				console.log('response', response);

				let users = response.map(usuarios =>
				{
					let u = GetEmpty.user();
					u.name = usuarios.name;
					u.phone = usuarios.phone || "";
					u.email = usuarios.email;
					u.type = "CLIENT";
					u.price_type_id = 1;
					u.store_id = usuarios.store_id;
					return u;
				});

				console.log('USUARIOS: ', users);

				this.subs.sink = this.rest.user.create(users).subscribe({
					next: (created_users) =>
					{
						this.showSuccess('Se crearon los clientes');

						console.log(response);

						let addresses = [];

						response.forEach((row_excel, index) =>
						{
							let address_str = row_excel.address ? row_excel.address.toString() : "";

							if (address_str.trim() !== "")
							{
							  let address = GetEmpty.address();

							  address.user_id = created_users[index].id;
							  address.address = address_str;
							  address.city = row_excel.city || "";
							  address.email = row_excel.email || "";
							  address.phone = row_excel.phone || "";
							  address.rfc = row_excel.rfc || "";
							  address.state = row_excel.state || "";
							  address.zipcode = row_excel.zipcode || "";
							  address.status = "ACTIVE";
							  address.type = "BILLING";
							  address.sat_regimen_capital = row_excel.sat_regimen_capital || "";
							  address.sat_regimen_fiscal = row_excel.sat_regimen_fiscal || "";
							  address.sat_uso_cfdi = row_excel.sat_uso_cfdi || "";
							  address.name = row_excel.sat_razon_social.trim() || row_excel.name.trim() || "";
							  addresses.push(address);
							}
						  });

						from(addresses)
						.pipe(
							bufferCount(100),
							mergeMap((address_batch) => this.rest.address.create(address_batch))
						)
						.subscribe(
						{
							next: () =>
							{
								this.showSuccess("Clientes y direcciones creados correctamente");
							},
							error: (error) =>
							{
								this.showError("Error al insertar direcciones: " + error);
							}
						});
					},
					error: (error) =>
					{
						this.showError("Error al insertar usuarios: " + error);
					}
				});
			});
	}

	orders_with_saldo_file: File | null = null;

	onFileOrdersChanged(evt:any)
	{
		if(evt.target.files.length)
		{

			this.orders_with_saldo_file = evt.target.files[0];
		}
		else
		{
			this.orders_with_saldo_file =	null;
		}
	}

	createOrders(evt:Event)
	{
		let headers = [ "name", "total","user_id", "currency_id", "paid" ];
		evt.preventDefault();

		let to_update = [];

		ExcelUtils.xlsx2json( this.orders_with_saldo_file,	headers )
		.then((response)=>
		{
			//let to_update = response.filter(x=>x.rfc );
			console.log('To update', response);
			let tax_percent = 16;

			//let observables = response.map((order_data)=>
			//{
			//	return createOrder
			//	(
			//		this.rest,
			//		order_data.name,
			//		order_data.user_id,
			//		order_data.total,
			//		tax_percent,
			//		order_data.paid,
			//		order_data.currency_id
			//	);

			//	//orderPayload
			//	//(
			//	//	rest:RestService,
			//	//	title:string,
			//	//	client_user_id:number,
			//	//	total:number,
			//	//	tax_percent:number,
			//	//	amount_paid:number,
			//	//	currency_id:string
			//	//)
			//});
			//
			console.log('Se pagaron ', response );

			response.forEach((order_data:any)=>{
				if( order_data.paid )
					console.log('Pagando Orden ', order_data.paid );
			});

			//let x = response.filter(x=>x.paid);

			this.subs.sink = from(response).pipe
			(
				concatMap((order_data:any)=>
				{
					//console.log('starting', order_data.name );
					console.log('Pagando Orden ', order_data.paid );
					return createOrder
					(
						this.rest,
						order_data.name,
						order_data.user_id,
						order_data.total,
						tax_percent,
						order_data.paid,
						order_data.currency_id
					);
				})
			)
			.subscribe
			({
				next:(response)=>
				{
					//console.log('termino con ', response);
				},
				error:(error)=>
				{
					console.log('Error on createOrders', error);
				}
			});

			//this.subs.sink =forkJoin( observables )
			//.subscribe
			//({
			//	next:(response)=>
			//	{
			//		console.log('Success');
			//		this.showSuccess('Success MTF');
			//	},
			//	error:(error)=>
			//	{
			//		console.log('Error BOOOO');
			//	}
			//});


			//this.subs.sink = from( response ).pipe
			//(
			//	map((x)=>
			//	{
			//		return createOrderWithSaldo
			//		({

			//		})
			//	})
			//).subscribe
			//(
			//	next:(response)=>
			//	{
			//		console.log('Se agrego
			//	},
			//	error:(error)=>
			//	{
			//		console.log('Error en el registro de la sincronizacion en background',error);
			//	}
			//});
		});
	}

	file_add_option_files: File | null = null;

	onAddOptionFileChanged(event:any)
	{
		if (event.target.files.length)
		{
			this.file_add_option_files = event.target.files[0];
		}
		else
		{
			this.file_add_option_files = null;
		}
	}

	addOptionItems(evt:Event)
	{
		this.is_loading = true;
		if( this.file_add_option_files == null )
		{
			this.showError('Debe seleccionar un archivo');
		}

		let headers = ['item_id'];

		ExcelUtils.xlsx2json( this.file_add_option_files,	headers )
		.then((response)=>
		{
			from( response ).pipe
			(
				concatMap((order_data:any)=>
				{
				return this.rest.item_info.get( order_data.item_id ).pipe
					(
						mergeMap((item_info:ItemInfo)=>
						{
							item_info.options.push
							({
								"values": [
									{
										"item_option_value": { "item_id": 620, "max_extra_qty": 10, "extra_price": 10, "price": 0, "portion_amount": 1 },
										"item": {
											"id": 620,
											"applicable_tax": "DEFAULT",
											"availability_type": "ALWAYS",
											"background": "#0d643b",
											"brand_id": null,
											"category_id": null,
											"clave_sat": "90101700",
											"code": null,
											"commanda_type_id": null,
											"commission_currency_id": "MXN",
											"commission_type": "NONE",
											"commission": 0,
											"created_by_user_id": null,
											"created": new Date(),
											"currency_id": "MXN",
											"description": "",
											"extra_name": "",
											"form_id": null,
											"response_requirement_qty": "ONCE_PER_CLIENT",
											"for_reservation": "NO",
											"has_serial_number": "NO",
											"tax_percent": 0,
											"ieps_type": "RATE",
											"ieps_value": 0,
											"image_id": null,
											"image_style": "COVER",
											"json_tags": [],
											"measurement_unit": null,
											"name": "bombones",
											"note_required": "NO",
											"on_sale": "NO",
											"partial_sale": "NO",
											"period_type": "MONTHLY",
											"product_id": null,
											"provider_user_id": null,
											"reference_currency_id": "MXN",
											"reference_price": 0,
											"return_action": "RETURN_TO_STOCK",
											"shadow_color": "#000000",
											"sort_weight": 1,
											"status": "ACTIVE",
											"text_color": "#FFFFFF",
											"text_style": "CENTER",
											"unidad_medida_sat_id": "H87",
											"updated_by_user_id": null,
											"updated": new Date()
										},
										"category": null
									}
								],
								"item_option": {
									"name": "Extra Bombones",
									"min_options": 0,
									"included_options": 1,
									"included_extra_qty": 1,
									"min_selections": 0,
									"max_extra_qty": 10,
									"max_options": 1
								}
							});

							return this.rest.item_info.update( item_info );
						})
					);
				})
			)
			.subscribe
			({
				next:(response)=>
				{

				}
				,error:(error)=>
				{
					console.log('Error on createOrders', error);
				}
			})
		});


	}
}


/*
	fetch("https://bme.integranet.xyz/api/updates.php", {
	"headers": {
	"accept": "application/json, text/plain, *\/*",
	"accept-language": "en-US,en;q=0.9,es-MX;q=0.8,es;q=0.7",
	"authorization": "Bearer WWFVzfY5YSQbZLMi",
	"content-type": "application/json",
	"sec-ch-ua": "\"Google Chrome\";v=\"119\", \"Chromium\";v=\"119\", \"Not?A_Brand\";v=\"24\"",
	"sec-ch-ua-mobile": "?0",
	"sec-ch-ua-platform": "\"Linux\"",
	"sec-fetch-dest": "empty",
	"sec-fetch-mode": "cors",
	"sec-fetch-site": "same-origin"
	},
	"referrer": "https://bme.integranet.xyz/",
	"referrerPolicy": "strict-origin-when-cross-origin",
	"body": "{\"stock_records\":[{\"created\":\"2023-12-14T18:35:04.351Z\",\"created_by_user_id\":3,\"description\":\"\",\"id\":0,\"is_current\":null,\"item_id\":15,\"movement_qty\":0,\"movement_type\":\"ADJUSTMENT\",\"order_item_id\":null,\"previous_qty\":0,\"production_item_id\":null,\"purchase_detail_id\":null,\"qty\":1,\"serial_number_record_id\":null,\"shipping_item_id\":null,\"store_id\":1,\"updated\":\"2023-12-14T18:35:04.351Z\",\"updated_by_user_id\":3},{\"created\":\"2023-12-14T18:35:04.351Z\",\"created_by_user_id\":3,\"description\":\"\",\"id\":0,\"is_current\":null,\"item_id\":16,\"movement_qty\":0,\"movement_type\":\"ADJUSTMENT\",\"order_item_id\":null,\"previous_qty\":0,\"production_item_id\":null,\"purchase_detail_id\":null,\"qty\":1,\"serial_number_record_id\":null,\"shipping_item_id\":null,\"store_id\":1,\"updated\":\"2023-12-14T18:35:04.351Z\",\"updated_by_user_id\":3},{\"created\":\"2023-12-14T18:35:04.351Z\",\"created_by_user_id\":3,\"description\":\"\",\"id\":0,\"is_current\":null,\"item_id\":17,\"movement_qty\":0,\"movement_type\":\"ADJUSTMENT\",\"order_item_id\":null,\"previous_qty\":0,\"production_item_id\":null,\"purchase_detail_id\":null,\"qty\":1,\"serial_number_record_id\":null,\"shipping_item_id\":null,\"store_id\":1,\"updated\":\"2023-12-14T18:35:04.351Z\",\"updated_by_user_id\":3},{\"created\":\"2023-12-14T18:35:04.351Z\",\"created_by_user_id\":3,\"description\":\"\",\"id\":0,\"is_current\":null,\"item_id\":18,\"movement_qty\":0,\"movement_type\":\"ADJUSTMENT\",\"order_item_id\":null,\"previous_qty\":0,\"production_item_id\":null,\"purchase_detail_id\":null,\"qty\":1,\"serial_number_record_id\":null,\"shipping_item_id\":null,\"store_id\":1,\"updated\":\"2023-12-14T18:35:04.351Z\",\"updated_by_user_id\":3}],\"method\":\"adjustStock\"}",
	"method": "POST",
	"mode": "cors",
	"credentials": "include"
	});

{
	"item": {
	"applicable_tax": "DEFAULT",
	"availability_type": "ALWAYS",
	"background": "transparent",
	"brand_id": null,
	"category_id": 16,
	"clave_sat": null,
	"code": null,
	"commanda_type_id": 2,
	"commission": 0,
	"commission_currency_id": "MXN",
	"commission_type": "NONE",
	"created": "2024-03-21T16:03:33.000Z",
	"created_by_user_id": null,
	"currency_id": "MXN",
	"description": null,
	"extra_name": null,
	"for_reservation": "NO",
	"form_id": null,
	"has_serial_number": "NO",
	"id": 59,
	"ieps_type": "RATE",
	"ieps_value": 0,
	"image_id": null,
	"image_style": "COVER",
	"json_tags": [],
	"measurement_unit": null,
	"name": "Espresso Chico",
	"note_required": "NO",
	"on_sale": "YES",
	"partial_sale": "NO",
	"period_type": "MONTHLY",
	"product_id": null,
	"provider_user_id": null,
	"reference_currency_id": "MXN",
	"reference_price": 0,
	"response_requirement_qty": "ONCE_PER_SALE",
	"return_action": "ADD_TO_MERMA",
	"shadow_color": "#000000",
	"sort_weight": 100,
	"status": "ACTIVE",
	"text_color": "#FFFFFF",
	"text_style": "CENTER",
	"unidad_medida_sat_id": "H87",
	"updated": "2024-05-02T18:50:17.000Z",
	"updated_by_user_id": null
	},
	"options": [
	{
		"item_option": {
		"id": 528,
		"included_extra_qty": 1,
		"included_options": 1,
		"item_id": 59,
		"max_extra_qty": 1,
		"max_options": 1,
		"min_options": 0,
		"min_selections": 0,
		"name": "Endulzantes",
		"status": "ACTIVE"
		},
		"values": [
		{
			"item": {
			"id": 163,
			"applicable_tax": "DEFAULT",
			"availability_type": "ALWAYS",
			"background": "transparent",
			"brand_id": null,
			"category_id": 68,
			"clave_sat": "",
			"code": null,
			"commanda_type_id": 2,
			"commission_currency_id": "MXN",
			"commission_type": "NONE",
			"commission": 0,
			"created_by_user_id": null,
			"created": "2024-05-08T18:24:27.000Z",
			"currency_id": "MXN",
			"description": null,
			"extra_name": null,
			"form_id": null,
			"response_requirement_qty": "ONCE_PER_SALE",
			"for_reservation": "NO",
			"has_serial_number": "NO",
			"ieps_type": "RATE",
			"ieps_value": 0,
			"image_id": null,
			"image_style": "COVER",
			"json_tags": [],
			"measurement_unit": "Pieza",
			"name": "Azúcar Regular",
			"note_required": "NO",
			"on_sale": "NO",
			"partial_sale": "NO",
			"period_type": "MONTHLY",
			"product_id": null,
			"provider_user_id": null,
			"reference_currency_id": "MXN",
			"reference_price": 0,
			"return_action": "ADD_TO_MERMA",
			"shadow_color": "#000000",
			"sort_weight": 1,
			"status": "ACTIVE",
			"text_color": "#FFFFFF",
			"text_style": "CENTER",
			"unidad_medida_sat_id": "H87",
			"updated_by_user_id": null,
			"updated": "2024-05-18T06:44:20.000Z"
			},
			"category": {
			"id": 68,
			"available_online": "YES",
			"background": "transparent",
			"code": null,
			"created_by_user_id": 3,
			"created": "2024-05-18T06:42:47.000Z",
			"default_clave_prod_serv": "90101700",
			"display_status": "HIDDEN",
			"image_id": null,
			"image_style": "CONTAIN",
			"name": "Endulzantes",
			"shadow_color": "#000000",
			"sort_weight": 10,
			"text_color": "#FFFFFF",
			"text_style": "CENTER",
			"type": "PRODUCT",
			"updated_by_user_id": 3,
			"updated": "2024-05-18T06:42:47.000Z"
			},
			"item_option_value": {
			"id": 3828,
			"charge_type": "OPTIONAL",
			"extra_price": 0,
			"item_id": 163,
			"item_option_id": 528,
			"max_extra_qty": 1,
			"portion_amount": 1,
			"price": 0,
			"status": "ACTIVE"
			}
		},
		{
			"item": {
			"id": 164,
			"applicable_tax": "DEFAULT",
			"availability_type": "ALWAYS",
			"background": "transparent",
			"brand_id": null,
			"category_id": 68,
			"clave_sat": "",
			"code": null,
			"commanda_type_id": 2,
			"commission_currency_id": "MXN",
			"commission_type": "NONE",
			"commission": 0,
			"created_by_user_id": null,
			"created": "2024-05-08T18:24:42.000Z",
			"currency_id": "MXN",
			"description": null,
			"extra_name": null,
			"form_id": null,
			"response_requirement_qty": "ONCE_PER_SALE",
			"for_reservation": "NO",
			"has_serial_number": "NO",
			"ieps_type": "RATE",
			"ieps_value": 0,
			"image_id": null,
			"image_style": "COVER",
			"json_tags": [],
			"measurement_unit": "Pieza",
			"name": "Azúcar Morena",
			"note_required": "NO",
			"on_sale": "NO",
			"partial_sale": "NO",
			"period_type": "MONTHLY",
			"product_id": null,
			"provider_user_id": null,
			"reference_currency_id": "MXN",
			"reference_price": 0,
			"return_action": "ADD_TO_MERMA",
			"shadow_color": "#000000",
			"sort_weight": 1,
			"status": "ACTIVE",
			"text_color": "#FFFFFF",
			"text_style": "CENTER",
			"unidad_medida_sat_id": "H87",
			"updated_by_user_id": null,
			"updated": "2024-05-18T06:44:28.000Z"
			},
			"category": {
			"id": 68,
			"available_online": "YES",
			"background": "transparent",
			"code": null,
			"created_by_user_id": 3,
			"created": "2024-05-18T06:42:47.000Z",
			"default_clave_prod_serv": "90101700",
			"display_status": "HIDDEN",
			"image_id": null,
			"image_style": "CONTAIN",
			"name": "Endulzantes",
			"shadow_color": "#000000",
			"sort_weight": 10,
			"text_color": "#FFFFFF",
			"text_style": "CENTER",
			"type": "PRODUCT",
			"updated_by_user_id": 3,
			"updated": "2024-05-18T06:42:47.000Z"
			},
			"item_option_value": {
			"id": 3829,
			"charge_type": "OPTIONAL",
			"extra_price": 0,
			"item_id": 164,
			"item_option_id": 528,
			"max_extra_qty": 1,
			"portion_amount": 1,
			"price": 0,
			"status": "ACTIVE"
			}
		},
		{
			"item": {
			"id": 227,
			"applicable_tax": "DEFAULT",
			"availability_type": "ALWAYS",
			"background": "transparent",
			"brand_id": null,
			"category_id": 68,
			"clave_sat": "",
			"code": null,
			"commanda_type_id": 2,
			"commission_currency_id": "MXN",
			"commission_type": "NONE",
			"commission": 0,
			"created_by_user_id": null,
			"created": "2024-05-08T21:32:09.000Z",
			"currency_id": "MXN",
			"description": null,
			"extra_name": null,
			"form_id": null,
			"response_requirement_qty": "ONCE_PER_SALE",
			"for_reservation": "NO",
			"has_serial_number": "NO",
			"ieps_type": "RATE",
			"ieps_value": 0,
			"image_id": null,
			"image_style": "COVER",
			"json_tags": [],
			"measurement_unit": "Pieza",
			"name": "Stevia",
			"note_required": "NO",
			"on_sale": "NO",
			"partial_sale": "NO",
			"period_type": "MONTHLY",
			"product_id": null,
			"provider_user_id": null,
			"reference_currency_id": "MXN",
			"reference_price": 0,
			"return_action": "ADD_TO_MERMA",
			"shadow_color": "#000000",
			"sort_weight": 1,
			"status": "ACTIVE",
			"text_color": "#FFFFFF",
			"text_style": "CENTER",
			"unidad_medida_sat_id": "H87",
			"updated_by_user_id": null,
			"updated": "2024-05-18T06:44:34.000Z"
			},
			"category": {
			"id": 68,
			"available_online": "YES",
			"background": "transparent",
			"code": null,
			"created_by_user_id": 3,
			"created": "2024-05-18T06:42:47.000Z",
			"default_clave_prod_serv": "90101700",
			"display_status": "HIDDEN",
			"image_id": null,
			"image_style": "CONTAIN",
			"name": "Endulzantes",
			"shadow_color": "#000000",
			"sort_weight": 10,
			"text_color": "#FFFFFF",
			"text_style": "CENTER",
			"type": "PRODUCT",
			"updated_by_user_id": 3,
			"updated": "2024-05-18T06:42:47.000Z"
			},
			"item_option_value": {
			"id": 3830,
			"charge_type": "OPTIONAL",
			"extra_price": 0,
			"item_id": 227,
			"item_option_id": 528,
			"max_extra_qty": 1,
			"portion_amount": 1,
			"price": 0,
			"status": "ACTIVE"
			}
		},
		{
			"item": {
			"id": 229,
			"applicable_tax": "DEFAULT",
			"availability_type": "ALWAYS",
			"background": "transparent",
			"brand_id": null,
			"category_id": 68,
			"clave_sat": "",
			"code": null,
			"commanda_type_id": 2,
			"commission_currency_id": "MXN",
			"commission_type": "NONE",
			"commission": 0,
			"created_by_user_id": null,
			"created": "2024-05-08T21:33:10.000Z",
			"currency_id": "MXN",
			"description": null,
			"extra_name": null,
			"form_id": null,
			"response_requirement_qty": "ONCE_PER_SALE",
			"for_reservation": "NO",
			"has_serial_number": "NO",
			"ieps_type": "RATE",
			"ieps_value": 0,
			"image_id": null,
			"image_style": "COVER",
			"json_tags": [],
			"measurement_unit": "Pieza",
			"name": "Splenda",
			"note_required": "NO",
			"on_sale": "NO",
			"partial_sale": "NO",
			"period_type": "MONTHLY",
			"product_id": null,
			"provider_user_id": null,
			"reference_currency_id": "MXN",
			"reference_price": 0,
			"return_action": "ADD_TO_MERMA",
			"shadow_color": "#000000",
			"sort_weight": 1,
			"status": "ACTIVE",
			"text_color": "#FFFFFF",
			"text_style": "CENTER",
			"unidad_medida_sat_id": "H87",
			"updated_by_user_id": null,
			"updated": "2024-05-18T06:44:47.000Z"
			},
			"category": {
			"id": 68,
			"available_online": "YES",
			"background": "transparent",
			"code": null,
			"created_by_user_id": 3,
			"created": "2024-05-18T06:42:47.000Z",
			"default_clave_prod_serv": "90101700",
			"display_status": "HIDDEN",
			"image_id": null,
			"image_style": "CONTAIN",
			"name": "Endulzantes",
			"shadow_color": "#000000",
			"sort_weight": 10,
			"text_color": "#FFFFFF",
			"text_style": "CENTER",
			"type": "PRODUCT",
			"updated_by_user_id": 3,
			"updated": "2024-05-18T06:42:47.000Z"
			},
			"item_option_value": {
			"id": 3831,
			"charge_type": "OPTIONAL",
			"extra_price": 0,
			"item_id": 229,
			"item_option_id": 528,
			"max_extra_qty": 1,
			"portion_amount": 1,
			"price": 0,
			"status": "ACTIVE"
			}
		}
		]
	},
	{
		"values": [
		{
			"item_option_value": {
			"item_id": 620,
			"max_extra_qty": 10,
			"extra_price": 10,
			"price": 0,
			"portion_amount": 1
			},
			"item": {
			"id": 620,
			"applicable_tax": "DEFAULT",
			"availability_type": "ALWAYS",
			"background": "#0d643b",
			"brand_id": null,
			"category_id": null,
			"clave_sat": "90101700",
			"code": null,
			"commanda_type_id": null,
			"commission_currency_id": "MXN",
			"commission_type": "NONE",
			"commission": 0,
			"created_by_user_id": null,
			"created": "2024-11-07T00:26:54.000Z",
			"currency_id": "MXN",
			"description": "",
			"extra_name": "",
			"form_id": null,
			"response_requirement_qty": "ONCE_PER_CLIENT",
			"for_reservation": "NO",
			"has_serial_number": "NO",
			"ieps_type": "RATE",
			"ieps_value": 0,
			"image_id": null,
			"image_style": "COVER",
			"json_tags": [],
			"measurement_unit": null,
			"name": "bombones",
			"note_required": "NO",
			"on_sale": "NO",
			"partial_sale": "NO",
			"period_type": "MONTHLY",
			"product_id": null,
			"provider_user_id": null,
			"reference_currency_id": "MXN",
			"reference_price": 0,
			"return_action": "RETURN_TO_STOCK",
			"shadow_color": "#000000",
			"sort_weight": 1,
			"status": "ACTIVE",
			"text_color": "#FFFFFF",
			"text_style": "CENTER",
			"unidad_medida_sat_id": "H87",
			"updated_by_user_id": null,
			"updated": "2024-11-07T00:26:54.000Z"
			},
			"category": null
		}
		],
		"item_option": {
		"name": "Extra Bombones",
		"min_options": 0,
		"included_options": 1,
		"included_extra_qty": 1,
		"min_selections": 0,
		"max_extra_qty": "10",
		"max_options": 1
		}
	}
	],
	"exceptions": [
	{
		"id": 32,
		"created": "2024-03-21T16:03:33.000Z",
		"description": "Vaso Caliente 12 Oz",
		"item_id": 59,
		"list_as_exception": "YES",
		"order_type": "ALL",
		"stock_item_id": 1,
		"stock_qty": 1,
		"updated": "2024-03-21T16:03:33.000Z",
		"stock_item": {
		"id": 1,
		"applicable_tax": "DEFAULT",
		"availability_type": "ALWAYS",
		"background": "transparent",
		"brand_id": null,
		"category_id": 6,
		"clave_sat": "50201708",
		"code": "VC12",
		"commanda_type_id": null,
		"commission_currency_id": "MXN",
		"commission_type": "NONE",
		"commission": 0,
		"created_by_user_id": null,
		"created": "2024-03-20T21:47:52.000Z",
		"currency_id": "MXN",
		"description": null,
		"extra_name": null,
		"form_id": null,
		"response_requirement_qty": "ONCE_PER_SALE",
		"for_reservation": "NO",
		"has_serial_number": "NO",
		"ieps_type": "RATE",
		"ieps_value": 0,
		"image_id": null,
		"image_style": "COVER",
		"json_tags": [],
		"measurement_unit": "Pieza",
		"name": "Vaso Caliente 12 Oz",
		"note_required": "NO",
		"on_sale": "NO",
		"partial_sale": "NO",
		"period_type": "MONTHLY",
		"product_id": null,
		"provider_user_id": null,
		"reference_currency_id": "MXN",
		"reference_price": 1.5,
		"return_action": "ADD_TO_MERMA",
		"shadow_color": "#000000",
		"sort_weight": 1,
		"status": "ACTIVE",
		"text_color": "#FFFFFF",
		"text_style": "CENTER",
		"unidad_medida_sat_id": "H87",
		"updated_by_user_id": null,
		"updated": "2024-03-20T21:47:52.000Z"
		},
		"stock_category": {
		"id": 6,
		"available_online": "YES",
		"background": "transparent",
		"code": null,
		"created_by_user_id": 2,
		"created": "2024-03-20T21:46:21.000Z",
		"default_clave_prod_serv": "90101700",
		"display_status": "HIDDEN",
		"image_id": null,
		"image_style": "CONTAIN",
		"name": "INSUMOS",
		"shadow_color": "#000000",
		"sort_weight": 10,
		"text_color": "#FFFFFF",
		"text_style": "CENTER",
		"type": "PRODUCT",
		"updated_by_user_id": 2,
		"updated": "2024-03-20T21:46:21.000Z"
		},
		"form_name": "0"
	}
	]
}
*/
