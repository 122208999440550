import	{	AfterViewChecked,	Component,	OnInit	}	from	'@angular/core';
import	{ParamMap}	from	'@angular/router';
import	{forkJoin,	from,	of}	from	'rxjs';
import	{debounceTime,	mergeMap}	from	'rxjs/operators';
import	{OrderInfo,	PaymentInfo}	from	'src/app/models/models';
import	{Billing_Data,	Currency_Rate,	Payment,	Store, Table	}	from	'src/app/models/RestModels';
import	{SearchObject}	from	'src/app/services/Rest';
import	{environment}	from	'src/environments/environment';
import	{BaseComponent}	from	'../base/base.component';
import	Hashids	from	'hashids';
import	{	NgxQrcodeElementTypes,	NgxQrcodeErrorCorrectionLevels	}	from	'@techiediaries/ngx-qrcode';
import	{	GetEmpty	}	from	'src/app/classes/Empties';





@Component({
  selector: 'app-print-receipt6',
  templateUrl: './print-receipt6.component.html',
  styleUrls: ['./print-receipt6.component.css']
})
export class PrintReceipt6Component extends BaseComponent implements OnInit,AfterViewChecked
{
	payment_info:PaymentInfo	=	null;
	order_info:OrderInfo	|	null;
	print_and_close:boolean	=	false;
	print_copies:number	=	1;
	copies:number[]=[1];
	that:any	=	this;
	discount:number	=	0;
	force_close:boolean	=	false;
	has_dolars:boolean	=	false;
	currency_rate:Currency_Rate	|	null	=	null;
	billing_data:Billing_Data	|	null	=	null;
	active:boolean	=	false;
	is_mobile:boolean	=	false;

	tax:number	= 0;
	ieps:number = 0;
	isr:number = 0;

	store:Store	= GetEmpty.store();

	url_for_facturacion:string	=	'https://'+window.location.hostname+'/facturar';
	element_type = NgxQrcodeElementTypes.URL;
	error_correction_level:	NgxQrcodeErrorCorrectionLevels.HIGH;

	hashids	=	new	Hashids
	(
		environment.app_settings.hashids_salt,
		environment.app_settings.hashids_length,
		environment.app_settings.hashids_alphabet
	);

	legend:Record<string,string>	=	{
		'CASH-MXN':'Efectivo',
		'CASH-USD':'Dolares',
		'CREDIT_CARD-MXN':'T.	Credito',
		'DEBIT_CARD-MXN':'T.	Debito',
		'CHECK-MXN':'Cheque',
		'TRANSFER-MXN':'Transferencia',
		'RETURN_DISCOUNT-MXN':'Descuento',
	}

	service_type_dictionary:Record<string,string>	=	{
		'TOGO':'PARA	LLEVAR',
		'IN_PLACE':'',
		'PICK_UP':'A	DOMICILIO',
	}

	beforeprint	=	()=>
	{
		console.log('beforeprint');
		if(	this.rest.printer_ticket_config	)
		{
			document.getElementById('print-config').innerHTML	=	this.rest.printer_ticket_config;
			return;
		}

		document.getElementById('print-config').innerHTML	=	'';
	};

	afterprint	=	function(){
		console.log('afterprint');
		document.getElementById('print-config').innerHTML	=	'';
	};

	show_bill_code:	boolean	=	true;
    table: Table | null = null;
    sat_ieps: number = 0;

	ngOnDestroy():	void	{
		this.active	=	false;
		window.removeEventListener('beforeprint',	this.beforeprint);
		window.removeEventListener('afterprint',	this.afterprint);
		super.ngOnDestroy();
	}

	ngAfterViewInit()
	{

	}

	ngAfterViewChecked()
	{
		this.active	=	true;
	}

	ngOnInit():	void
	{
		window.addEventListener('beforeprint'	,	this.beforeprint);
		window.addEventListener('afterprint'	,	this.afterprint	);

		this.subs.sink	=	this.route.paramMap.pipe
		(
			debounceTime(200)
		)
		.subscribe((paramMap:ParamMap)=>
		{
			console.log('FOOOOO',paramMap);
			this.currency_rate	=	null;
			this.print_and_close	=	paramMap.has('print');
			this.print_copies	=	paramMap.has('copies')	?	parseInt(paramMap.get('copies'))	:	1;
			this.force_close	=	paramMap.has('print');

			console.log('Print	copies	has',	this.copies,	'Cerrar',	this.force_close	);
			let	c	=	new	Array(	this.print_copies	);
			c.fill(1);
			this.copies	=	c;

			let	search:SearchObject<Payment>	=	this.getEmptySearch();
			search.search_extra	=	{order_id:paramMap.get('order_id')};
			search.limit	=	1;
			search.sort_order=['id_ASC'];

			if(	this.print_and_close	)
			{
				console.log('Adding	force	close',	this.afterprint	);

				//window.addEventListener('afterprint',this.afterprint);
				window.onafterprint=this.afterprint
			}

			console.log("Se	inicializo	cuantas	veces");

			let	something	=	paramMap.has('order_id')	?	paramMap.get('order_id')	:	paramMap.get('order_sync_id');

			this.setTitle('ReciboOrden-'+something);

			if(	paramMap.has('order_id')	)
			{
				this.subs.sink	=	this.rest.order_info.get(	paramMap.get('order_id')	).pipe
				(
					mergeMap((response)=>{
						return forkJoin({
							order_info: of( response ),
							table: response.order.table_id ? this.rest.getTable( response.order.table_id ) : of( null ),
						})
					}),
					mergeMap((resp)=>
					{
						let order_info = resp.order_info;
						return	forkJoin
						({
							order:	of(	resp.order_info ),
							store:	this.rest.getStore(	order_info.order.store_id	),
							payment_info:	this.rest.payment_info.search(	search	),
							stores:	this.rest.getStores(true),
							billing_data:	this.rest.getBillingData(true),
							price_type: this.rest.getPriceTypes(),
							table: of( resp.table )
						})
					})
				)
				.subscribe((responses)=>
				{
					let tax = 0;
					let ieps = 0;
					let isr = 0;

					this.tax = tax;

					for(let oii of responses.order.items)
					{
						oii.serials_string	=	oii.serials
							.map((i)=>i.serial.serial_number)
							.join(',');

						if( oii.order_item.type == 'REFUND' )
							continue;

						let tax_percent = oii.item.applicable_tax == 'DEFAULT' ? responses.order.order.tax_percent : 0;
						let ieps_percent = oii.order_item.ieps_type == 'RATE' ? oii.order_item.ieps_value : 0;

						let base = oii.order_item.total/(1+((tax_percent+ieps_percent)/100));
						let iva_factura = base*(tax_percent/100);
						let ieps_factura = base*(ieps_percent/100);

						tax += iva_factura;
						ieps += ieps_factura;
					}

					this.sat_ieps = ieps;
					this.tax = tax;


					this.rest.printer_ticket_config	=	responses.store.printer_ticket_config;
					this.rest.createStructuredItems(	responses.order	);
					this.billing_data	=	responses.billing_data.data.find(bd=>bd.id	==	responses.store.default_billing_data_id)	||	null;
					this.table = responses.table;

					responses.order.price_type = responses.price_type.data.find((pt)=>pt.id == responses.order.order.price_type_id);
					this.order_info	=	responses.order;
					this.setShowCode();

					if(	responses.payment_info.data.length	)
					{
						this.payment_info=	responses.payment_info.data[0];
					}

					if(	this.payment_info?.movements	)
					{
						dance:
						for(let	bmi	of this.payment_info.movements)
						{
							for(let	bmo	of bmi.bank_movement_orders)
							{
								if(	bmo.currency_id	!=	this.order_info.store.default_currency_id	)
								{
									this.currency_rate	=	{
										store_id:	responses.order.store.id,
										currency_id:	bmo.currency_id,
										rate:	bmo.exchange_rate
									};
									break dance;
								}
							}
						}
					}

					if(	this.print_and_close	)
					{
						console.log('Pringint');
						setTimeout(()=>{
							console.log('IS	active	to	print',this.active);
							if(	this.active	)
								window.print()

								if(	!this.is_mobile	)
									window.close();
						},700);
					}
				},(error)=>this.showError(error));
			}
			else
			{
				this.subs.sink	=	forkJoin
				({
					order:	from(	this.rest.getOfflineOrderInfoBySyncId(	paramMap.get('order_sync_id')	)),
					payment:	from(this.rest.getOfflinePaymentByOderSyncId(	paramMap.get('order_sync_id')	)),
					stores:	this.rest.getStores(true)
				})
				.subscribe((response)=>
				{
					try
					{
						this.payment_info	=		response.payment;
						this.order_info	=	response.order;

						if(	this.payment_info.movements	)
						{
							dance:
							for(let	bmi	of	response.payment.movements)
							{
								for(let	bmo	of	bmi.bank_movement_orders	)
								{
									if(	bmo.currency_id	!=	this.order_info.store.default_currency_id	)
									{
										this.currency_rate	=	{
											store_id:	response.order.store.id,
											currency_id:	bmo.currency_id,
											rate:	bmo.exchange_rate
										};
										break	dance;
									}
								}
							}
						}

						console.log('Buscqando	order		',response	);
						//this.order_info	=	order_info;
						console.log('payment	info	',this.payment_info);

						if(	this.print_and_close	)
						{
							console.log('Pringint');
							setTimeout(()=>{
								window.print()

								if(	!this.is_mobile	)
									window.close();
							},700);
						}
					}
					catch(e)
					{
						console.error('error	ocurrido',	e	);
						this.showError('Ocurrio	un	error	aqui');
					}
				});
			}
		});
	}

	/*
1-1643157008003
1-1643157096411
1-1643157134876
*/

	facturar(_evt:Event)
	{
		let	url	=	this.rest.getUrlBase()+'/facturar.php?id='+this.order_info.order.id;

		this.subs.sink	=	this.rest
			.getUrlAsString(	url	)
			.subscribe((response)=>
			{
				this.showSuccess('Exito');
			},(error)=>
			{
				this.showError(error);
			})
	}

	justPrint()
	{
		window.print();
	}
	closeWindow()
	{
		window.close();
	}

	setShowCode()
	{
		this.show_bill_code	=	this.order_info	&&
			this.order_info.order.status == 'CLOSED' &&
			this.order_info.order.cancellation_timestamp == null &&
			this.order_info.store.autofacturacion_settings	!=	'DISABLED'	&&
			this.order_info.store.default_billing_data_id	&&
			this.order_info?.price_type?.show_bill_code	!=	'NO'
	}
}
