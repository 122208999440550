import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {mergeMap} from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import {FacturacionRequest, OrderInfo, PaymentInfo} from 'src/app/models/models';
import {Address, Bank_Movement, Billing_Data} from 'src/app/models/RestModels';
import { RestResponse } from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-save-order-factura',
	templateUrl: './save-order-factura.component.html',
	styleUrls: ['./save-order-factura.component.css']
})

export class SaveOrderFacturaComponent extends BaseComponent implements OnInit,OnChanges
{
	@Input() order_info:OrderInfo = null;
	@Output() onFinish = new EventEmitter<boolean>();
	@Input() billing_data_list:Billing_Data[] = [];
	@Input() payment_info_list:PaymentInfo[] = [];

	billing_data_dictionary:Record<number,Billing_Data> = {};
	billing_data_id:number | null = null;
	selected_billing_data:Billing_Data | null = null;
	address_list:Address[] = null;

	client_address:Address = null;

	version:'4.0'|'3.0' = '4.0';

	ngOnInit(): void
	{
		this.subs.sink = this.rest.billing_data
		.search({limit:989999})
		.subscribe((responses:RestResponse<Billing_Data>)=>
		{
			this.billing_data_dictionary = Utils.createDictionary(responses.data, 'id');
		});
	}

	ngOnChanges(_changes: SimpleChanges): void
	{
		if( this.order_info )
		{
			//busca todas las direcciones
			this.subs.sink = this.rest.address.search({nn:['rfc'],limit:9999,sort_order:['id_DESC']})
			.subscribe((response)=>
			{
				//si la orden fue registrada con usuario
				if( this.order_info.order.client_user_id )
				{
					this.client_address = response.data.find((address)=>address.user_id == this.order_info.order.client_user_id);
				}
				else
				{
					this.client_address = response.data[0];
				}
				this.address_list = response.data;
				this.setData(this.client_address);
			});
		}

		if( _changes.payment_info_list )
		{
			if( this.order_info )
			{
				if( this.payment_info_list.length == 1 )
				{
					this.order_info.order.sat_forma_pago = this.getBiggestPaymentMethod(this.payment_info_list[0]);
				}
				else( this.payment_info_list.length > 0 )
				{
					this.order_info.order.sat_forma_pago = '99';
				}
			}
		}
	}

	getBiggestPaymentMethod(payment_info:PaymentInfo)
	{
		switch( payment_info.movements[0].bank_movement.transaction_type )
		{
			case 'CASH': return '01';
			case 'CHECK': return '02';
			case 'TRANSFER': return '03';
			case 'CREDIT_CARD': return '04';
			case 'DEBIT_CARD': return '28';
		}

		return '01';
	}

	onBillingDataChange(billing_data_id:number)
	{
		if ( billing_data_id == null )
		{
			this.order_info.order.sat_codigo_postal = '';
			return;
		}

		this.selected_billing_data = this.billing_data_dictionary[billing_data_id];
		this.order_info.order.sat_codigo_postal = this.selected_billing_data.zipcode;
	}

	changeAddress(razon_social:string)
	{
		if( !razon_social )
			return;

		this.client_address = this.address_list.find((address)=>address.name == razon_social);
		this.updateData(this.client_address);
	}

	updateData(client_address:Address)
	{
		this.order_info.order.sat_receptor_rfc = client_address.rfc ? client_address.rfc : '';
		this.order_info.order.sat_razon_social = client_address.name ? client_address.name : '';
		this.order_info.order.sat_receptor_email = client_address.email ? client_address.email : '';
		this.order_info.order.sat_regimen_capital_receptor = client_address.sat_regimen_capital ? client_address.sat_regimen_capital : '';
		this.order_info.order.sat_domicilio_fiscal_receptor = client_address.zipcode ? client_address.zipcode : '';
		this.order_info.order.sat_regimen_fiscal_receptor = client_address.sat_regimen_fiscal ? client_address.sat_regimen_fiscal : '';
		this.order_info.order.sat_uso_cfdi = client_address.sat_uso_cfdi ? client_address.sat_uso_cfdi : '';
	}

	setData(client_address:Address)
	{
		//this.order_info.order.sat_receptor_rfc = 'XAXX010101000';
		//this.order_info.order.sat_razon_social = 'PÚBLICO GENERAL';
		//this.order_info.order.sat_codigo_postal = '22800';

		// let address = this.address_list.find((address)=>address.rfc ? true : false);

		if( !(this.order_info.order.sat_receptor_rfc) && client_address?.rfc )
		{
			this.order_info.order.sat_receptor_rfc = client_address.rfc;
		}

		if( !(this.order_info.order.sat_codigo_postal) && this.order_info?.store?.zipcode )
		{
			this.order_info.order.sat_codigo_postal = this.order_info.store.zipcode;
		}

		if( !(this.order_info.order.sat_razon_social) && client_address?.name )
		{
			this.order_info.order.sat_razon_social = client_address.name;
		}

		if( !(this.order_info.order?.sat_receptor_email) && client_address?.email )
		{
			this.order_info.order.sat_receptor_email = client_address.email;
		}

		if( !(this.order_info?.order?.sat_regimen_capital_receptor) && client_address?.sat_regimen_capital )
		{
			this.order_info.order.sat_regimen_capital_receptor = client_address.sat_regimen_capital;
		}

		if( !(this.order_info.order?.sat_domicilio_fiscal_receptor) && client_address?.zipcode )
		{
			this.order_info.order.sat_domicilio_fiscal_receptor = client_address.zipcode;
		}

		if( !(this.order_info.order?.sat_regimen_fiscal_receptor) && client_address?.sat_regimen_fiscal )
		{
			this.order_info.order.sat_regimen_fiscal_receptor = client_address.sat_regimen_fiscal;
		}

		if( !(this.order_info.order?.sat_uso_cfdi) && client_address?.sat_uso_cfdi )
		{
			this.order_info.order.sat_uso_cfdi = client_address.sat_uso_cfdi;
		}

		if( !this.order_info.order.sat_serie )
		{
			this.subs.sink = this.rest.store
			.get( this.order_info.order.store_id )
			.subscribe((store)=>
			{
				this.order_info.order.sat_serie = store.default_sat_serie;
			});
		}

		if( this.order_info.order.billing_data_id &&  !(this.order_info.order.sat_codigo_postal) && this.billing_data_list.length > 0)
		{
			this.order_info.order.sat_codigo_postal = this.billing_data_dictionary[this.order_info.order.billing_data_id] ? this.billing_data_dictionary[this.order_info.order.billing_data_id].zipcode : '';
		}
	}

	cancel()
	{
		this.onFinish.emit( false );
	}

	save(evt:Event)
	{
		if( this.order_info.order.billing_data_id == null )
		{
			this.showError('Por favor selecciona los datos de facturacion');
			evt.preventDefault();
			evt.stopPropagation();
			return;
		}

		this.showWarning('Facturando por favor espere');

		this.is_loading = true;
		this.subs.sink = this.rest.updateDatosFacturacion( this.order_info.order )
		.pipe
		(
			mergeMap(()=>
			{
				let request:FacturacionRequest = {
					facturacion_code: this.order_info.order.facturacion_code,
					razon_social: this.order_info.order.sat_razon_social,
					email: this.order_info.order.sat_receptor_email,
					rfc: this.order_info.order.sat_receptor_rfc,
					domicilio_fiscal: this.order_info.order.sat_domicilio_fiscal_receptor,
					lugar_de_expedicion: '',
					forma_de_pago: this.order_info.order.sat_forma_pago,
					regimen_fiscal:this.order_info.order.sat_regimen_fiscal_receptor,
					regimen_capital: this.order_info.order.sat_regimen_fiscal_receptor,
					uso_cfdi: this.order_info.order.sat_uso_cfdi,
					version: this.version,
					note: this.order_info.order.note
				};
				return this.rest.facturacion_request.create( request );
			})
		)
		.subscribe((_response)=>
		{
			this.is_loading = false;
			this.onFinish.emit(true);
			this.showSuccess('Se facturo la orden correctamente');
		},(error)=>this.showError(error));
	}
}
