import { Component } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import { forkJoin, from, of } from 'rxjs';

import {Billing_Data, Order, Payment, User} from '../../models/RestModels';
import {Store} from '../../models/RestModels';
import {Price_Type} from '../../models/RestModels';
import { OrderInfo, PaymentInfo, ReturnsInfo } from '../../models/models';
import {ParamMap} from '@angular/router';
import {mergeMap} from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';

interface CustomOrderInfo extends OrderInfo
{
	article_discount:number;
	checked:boolean;
	paid_cash:boolean;
	paid_card:boolean;
	paid_transfer:boolean;
	paid_check:boolean;
	paid_other:boolean;
	price_type_name:string;
	disabled:boolean;
	client_name:string;
	link:(string|number)[];
}

@Component
({
	selector: 'app-facturacion-periodo',
	templateUrl: './facturacion-periodo.component.html',
	styleUrls: ['./facturacion-periodo.component.css']
})
export class FacturacionPeriodoComponent extends BaseComponent
{
	file:File = null;
	show_import:boolean = false;
	billing_data_id:number | '' = '';
	billing_data_list:Billing_Data[] = [];

	order_search:SearchObject<Order> = this.getEmptySearch();
	search_extra:Record<string,string|null> = { };
	order_info_list:CustomOrderInfo[] = [];
	all_checked:boolean = true;
	store_list:Store[] = [];
	price_type_list:Price_Type[] = [];
	store_dictionary:Record<number,Store> = {};
	price_type_dic:Record<number,Price_Type> = {};
	order_total:number = 0;
	ieps_total:number = 0;
	show_asign_delivery_user:boolean = false;
	selected_order_info:OrderInfo = null;
	show_returns:boolean = false;
	cashier_user_list:User[] = [];
	store_id:number = 1;

	orders_selected:number[] = [];
	total_facturacion:number;
	ieps_seleccionado:number = 0;
	sat_serie:string = 'A';
	sat_forma_de_pago:string = '';
	serie:string = 'A';
	payment_info_list:PaymentInfo[];
	show_facturar_periodo:boolean = false;
	codigo_periocidad:string = '01';
	codigo_mes:string = '';
	anio:string= '';
	years:string[] = []
	tax_percent:string = '';
	fecha_inicial:string = '';
	fecha_final:string = '';
	percentage_to_select: number = 0;
	iva_total:number;
	transaction:string = '';
	lugar_de_expedicion:string = '';

	ngOnInit()
	{
		this.path = '/facturacion-periodo';
		//this.rest.has_hades = true;

		let year_date = new Date();
		year_date.setFullYear( year_date.getFullYear() - 1 );

		for(let i=0;i<3;i++)
		{
			this.years.push(''+(year_date.getFullYear()+i));
		}

		this.subs.sink = this.route.queryParamMap
		.pipe
		(
			mergeMap((queryParamMap:ParamMap)=>
			{
				let fields = [ "id","client_user_id","cashier_user_id","store_id","shipping_address_id",
					"price_type_id","status","type","client_name","total","subtotal","tax","amount_paid",
					"address","suburb","city","state","zipcode","name","created_by_user_id",
					"delivery_status", "updated_by_user_id","created","updated" ];

				let extra_keys:Array<string> = ['transaction_type','publico_general']; //['search_param1','project_id','some_id'];
				this.order_search = this.getSearch(queryParamMap, fields, extra_keys);
				this.setTitle('Facturación Periodo');
				this.is_loading = true;
				this.current_page = 0;//this.order_search.page;

				if( this.rest.has_ares )
				{
					this.order_search.search_extra['hades'] = '1';
				}

				if( !this.order_search.ge.created )
				{
					let d = new Date();
					d.setDate(1);
					d.setHours(0,0,0,0);
					this.order_search.ge.created = d;
				}

				if(this.order_search?.eq?.store_id)
				{
					this.store_id = this.order_search.eq.store_id;
				}

				this.fecha_inicial = Utils.getLocalMysqlStringFromDate( this.order_search.ge.created );
				console.log('Fecha inicial', this.fecha_inicial);

				if( !this.order_search.le.created )
				{
					let d = Utils.getEndOfMonth( this.order_search.ge.created );
					this.order_search.le.created = d;
				}

				this.fecha_final = Utils.getLocalMysqlStringFromDate( this.order_search.le.created ).substring(0,16);

				this.order_search.csv.status = ['CLOSED'];
				this.order_search.is_null = [];
				//this.order_search.eq.tax_percent = 8;
				//this.order_search.eq.currency_id = 'MXN';
				this.order_search.limit = 999999999999999;
				this.order_search.sort_order = ['id_DESC'];
				this.is_loading = true;
				this.sat_serie = 'A';

				if( this.order_search.search_extra.publico_general )
				{
					this.order_search.is_null = ['client_user_id']
				}
				else
				{
					this.order_search.is_null = [];
				}

				this.order_search.is_null.push('sat_factura_id');


				this.order_search.eq.paid_status = 'PAID';

				let as_post = true;
				return this.downloadAll( this.rest.order_info, this.order_search, as_post );
			}),
			mergeMap((order_data)=>
			{
				let tmp_total = order_data.data.reduce((p,c)=>p+(c.order.total-c.order.discount),0);

				this.ieps_total = order_data.data.reduce((totalIeps, order) => {
					return totalIeps + order.order.sat_ieps;
				}, 0);

				this.iva_total = order_data.data.reduce((totalIva, order) =>
				{
					return totalIva + order.order.tax;
				}, 0);

				let store_obs = this.store_list.length > 0
					? of({data:this.store_list, total: this.store_list.length})
					: this.rest.store.search({limit:999999, eq:{sales_enabled:1}, sort_order:['name_ASC']});

				return forkJoin
				({
					order : of( order_data ),
					store : store_obs,
					price_type : this.rest.getPriceTypes(),
					users: this.rest.is_offline
						? of({data:[], total: 0})
						: this.rest.user.search({limit:9999, eq:{type:'USER'},sort_order:['name_ASC']}),
					order_total: this.rest.is_offline
						? of({total:tmp_total,data:[]})
						: this.rest.getOrderTotal(this.order_search),
					billing_data: this.rest.getBillingData(false),
					funds: this.rest.fund.search
					({
							ge:{ created: this.order_search.ge.created},
							le:{ created: this.order_search.le.created},
							limit: 99999
					})
				})
			}),
			mergeMap((response)=>
			{
				let ids = response.order.data.map(i=>i.order.id).sort((a, b) => a - b);
				let order_ids:string = Utils.generateRangeString( ids );
				this.billing_data_list = response.billing_data.data;

				let as_post = true;
				let search:SearchObject<Payment> = this.getEmptySearch();
				search.search_extra = { order_id:order_ids }
				search.limit = 9999999;

				let payment_obs = ids.length ==0
					? of({data:[],total:0})
					: this.downloadAll( this.rest.payment_info, search , as_post);

				return forkJoin
				({
					payments: payment_obs,
					order : of( response.order ),
					store : of( response.store ),
					price_type : of( response.price_type),
					users: of(response.users),
					order_total: of( response.order_total),
					funds: of( response.funds )
				})
			})
		)
		.subscribe((responses)=>
		{
			this.transaction = 'fg-'+Date.now();
			responses.store.data.forEach(store=>this.store_dictionary[store.id]=store);

			this.store_list = responses.store.data;

			let store = this.store_list.find(s=>s.id == this.rest.current_user.store_id );
			let billing_data = this.billing_data_list.find(bd=>bd.id == store.default_billing_data_id);
			this.billing_data_id = billing_data.id;

			this.serie = store.default_sat_serie;

			responses.price_type.data.forEach(price_type=>this.price_type_dic[ price_type.id ] = price_type);
			this.payment_info_list = responses.payments.data;

			//this.store_id = this.rest.current_user.store_id;
			this.sat_serie = this.store_dictionary[ store.id ].default_sat_serie;
			this.cashier_user_list	= responses.users.data;
			this.price_type_list	= responses.price_type.data;
			this.order_total		= responses.order_total.total;
			this.order_info_list	= responses.order.data.map(oi=> this.getCustomOrderInfo(oi));
			this.is_loading	= false;
			this.updateSelectedTotal();
		},(error)=>
		{
			this.showError(error)
		});
	}

	confirmReadyToPickup(order_info:OrderInfo)
	{
		this.subs.sink = this.confirmation.showConfirmAlert(order_info,'Marcar Como Listo para recoger','Esta seguro de querer marcar la orden como preparada y lista para recoger')
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				this.subs.sink = this.rest.updateOrderDeliveryStatus(order_info.order.id, 'READY_TO_PICKUP').subscribe(()=>
				{
					order_info.order.delivery_status = 'READY_TO_PICKUP';
					this.rest.sendNotification('order', order_info.order.id );
					this.showSuccess('La orden se marco como lista para recoger');
				},(error)=>this.showError(error));
			}
		});
	}
	confirmSend(order_info:OrderInfo)
	{
		this.subs.sink = this.confirmation.showConfirmAlert(order_info,'Marcar Como Enviado','Esta seguro de querer marcar la orden como enviada')
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				this.subs.sink = this.rest.updateOrderDeliveryStatus(order_info.order.id, 'SENT').subscribe(()=>
				{
					order_info.order.delivery_status = 'SENT';
					this.showSuccess('La orden se marco como enviada');
					this.rest.sendNotification('order', order_info.order.id);
				},(error)=>this.showError(error));
			}
		});
	}
	showSelectDeliveryUser(selected_order_info:OrderInfo)
	{
		this.selected_order_info = selected_order_info;
		this.show_asign_delivery_user = true;
	}
	onDeliveryUserSelected(user:User | null)
	{
		this.show_asign_delivery_user = false;

		if( user != null )
		{
			this.selected_order_info.order.delivery_user_id = user.id;
		}
	}

	confirmDelivered(order_info:OrderInfo)
	{
		this.selected_order_info = order_info;
		this.subs.sink = this.confirmation.showConfirmAlert(order_info,'Marcar Como Entregado','Esta seguro de querer marcar la orden como Entregada')
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				this.subs.sink = this.rest.updateOrderDeliveryStatus( this.selected_order_info.order.id, 'DELIVERED' )
				.subscribe(()=>
				{
					this.rest.sendNotification('order', order_info.order.id);
					this.selected_order_info.order.delivery_status = 'DELIVERED';
					this.rest.sendNotification('order', order_info.order.id);
				},(error)=>{this.showError(error)});
			}
		});
	}

	togglePublicoGeneral(evt:Event)
	{
		let mouse_event:MouseEvent = evt as MouseEvent;
		let checkbox:HTMLInputElement = evt.target as HTMLInputElement;
		//checkbox.checked = !checkbox.checked;

		this.order_search.search_extra.publico_general = checkbox.checked ? '1': null;
	}

	search(search_object:SearchObject<Order>)
	{
		search_object.eq.client_user_id = null;
		super.search( search_object )
	}

	showOrderReturnModal(order_info:OrderInfo)
	{
		this.selected_order_info = order_info;
		this.show_returns = true;
	}

	onOrderReturned(returns_info:ReturnsInfo)
	{
		this.show_returns = false;
	}

	updateSelectedTotal()
	{
		let total = 0;
		let iepsTotal = 0;

		for(let oi of this.order_info_list)
		{
			if( oi.checked )
			{
				total += (oi.order.total-oi.order.discount);
				iepsTotal += oi.order.sat_ieps;
			}
		}
		this.total_facturacion = total;
		this.ieps_seleccionado = iepsTotal;
	}

	showFacturar()
	{
		this.show_facturar_periodo = true;
		//this.subs.sink = this.confirmation.showConfirmAlert({}, 'Facturar Periodo', 'Esta seguro que desea facturar por el monto de '+(Math.round(this.total_facturacion*100)/100))
		//.subscribe((response)=>
		//{
		//	if( response.accepted )
		//	{
		//		let ids = this.order_info_list
		//			.filter(oi=>!oi.disabled && oi.checked)
		//			.map(i=>i.order.id)
		//			.join(',');

		//		let store = this.store_list.find(s=>s.id == this.store_id);

		//		this.subs.sink = this.rest.facturarPeriodo
		//		(
		//			ids,
		//			store.id,
		//			store.default_billing_data_id,
		//			this.order_search.eq.tax_percent,
		//			this.order_search.eq.currency_id,
		//			this.sat_serie
		//		)
		//		.subscribe(()=>
		//		{
		//			this.showSuccess('Se facturo correctamente');
		//		},(error)=>this.showError(error));
		//	}
		//})
	}

	toggleAll()
	{
		this.all_checked= !this.order_info_list.some(oi=>!oi.checked);

		this.all_checked = !this.all_checked;

		for(let oi of this.order_info_list)
		{
			if( oi.disabled )
			{
				continue;
			}

			oi.checked = this.all_checked;
		}

		this.updateSelectedTotal();
	}

	getCustomOrderInfo(order_info:OrderInfo):CustomOrderInfo
	{
		let payments = this.payment_info_list.filter(payment=>
		{
			return payment.movements.some((bm)=>
			{
				return bm.bank_movement_orders.some(bmo=>bmo.order_id == order_info.order.id);
			});
		});

		let paid_cash = payments.some(pi=>this.testPaymentMethod('CASH',pi,order_info.order.id));
		let paid_card = payments.some(pi=>this.testPaymentMethod('CREDIT_CARD',pi,order_info.order.id)) ||
		payments.some(pi=>this.testPaymentMethod('DEBIT_CARD',pi,order_info.order.id));

		let paid_transfer = payments.some(pi=>this.testPaymentMethod('TRANSFER',pi,order_info.order.id));
		let paid_check	= payments.some(pi=>this.testPaymentMethod('CHECK',pi,order_info.order.id));
		let paid_other	= payments.some(pi=>this.testPaymentMethod('PAYPAL',pi,order_info.order.id));

		let disabled	= false;//paid_card|| paid_transfer || order_info.order.facturado == 'YES';
		let checked	= !( order_info.order.sat_factura_id != null);

		let article_discount = order_info.items.reduce((prev,oi)=>
		{
			//If they are equal then there isn't a discount
			if( oi.order_item.original_unitary_price == oi.order_item.unitary_price_meta )
				return prev;

			//Discount must be with the tax included

			let tax = oi.order_item.tax == 0 ? 0 :order_info.order.tax_percent;

			//Tax exepmt
			if( tax == 0 )
				return prev+(oi.order_item.unitary_price_meta-oi.order_item.original_unitary_price)*oi.order_item.qty;

			let does_price_include_tax = oi.order_item.original_unitary_price > (oi.order_item.unitary_price+0.001);
			let price_with_tax = does_price_include_tax ? oi.order_item.unitary_price_meta : (oi.order_item.unitary_price_meta*( 1 + order_info.order.tax_percent ));
			let original_total = oi.order_item.qty* price_with_tax;

			return prev+(original_total-oi.order_item.total);
		},0);

		let price_type = this.price_type_list.find(pt=>pt.id == order_info.order.price_type_id );

		return {
			...order_info,
			article_discount,
			paid_cash,
			paid_card,
			paid_transfer,
			paid_check,
			paid_other,
			disabled,
			price_type_name: price_type?.name || '',
			checked,
			client_name: (order_info?.client?.name || order_info.order.client_name || '').toUpperCase(),
			link: order_info.order.status == 'CLOSED' || order_info.order.status == 'CANCELLED'
					? ['/view-order',order_info.order.id]
					: ['/pos/',order_info.order.id]
		}
	}

	updateTotal()
	{
		this.total_facturacion = this.order_info_list.reduce((p,oi)=>
		{
			if( !oi.checked || oi.disabled)
				return p;

			return p+oi.order.total-oi.order.discount
		},0);
	}

	clickedOnOrder(coi:CustomOrderInfo)
	{
		coi.checked= !coi.checked;

		if( !coi.checked )
		{
			this.all_checked = false;
		}
		else
		{
			this.all_checked= !this.order_info_list.some(oi=>!oi.checked);
		}

		this.updateSelectedTotal();
	}

	testPaymentMethod(method:string,payment_info:PaymentInfo,order_id:number)
	{
		return payment_info.movements.some((bm)=>
		{
			if( bm.bank_movement_orders.some(bmo=>bmo.order_id == order_id) )
			{
				return bm.bank_movement.transaction_type == method;
			}
			return false;
		});
	}

	facturarPeriodo(evt:Event)
	{
		evt.preventDefault();

		let ids = this.order_info_list
			.filter(oi=>!oi.disabled && oi.checked)
			.map(i=>i.order.id)
			.join(',');

		let first_id = this.order_info_list.map(i=>i.order.id).sort((a,b)=>a-b)[0];

		let store = this.store_list.find(s=>s.id == this.store_id);

		this.is_loading = true;

		this.subs.sink = this.rest.facturarPeriodo
		({
			order_ids:ids,
			store_id: store.id,
			billing_data_id: this.billing_data_id,
			serie:this.sat_serie,
			codigo_mes:this.codigo_mes,
			codigo_periocidad: this.codigo_periocidad,
			currency_id: 'MXN',
			anio:this.anio,
			tax_percent:this.tax_percent,
			transaction: this.transaction,
			forma_de_pago: this.sat_forma_de_pago,
			lugar_de_expedicion: this.lugar_de_expedicion
		})
		.subscribe(()=>
		{
			this.showSuccess('Se facturo correctamente');
			this.show_facturar_periodo	= false;
		},(error)=>this.showError(error));
	}

	selectPercentage()
	{
		let selected = [];
		let trys = 0;
		let list_length = this.order_info_list.length;

		console.log('WTF WITH THIS');

		for(let oi of this.order_info_list)
		{
			oi.checked = false;
		}

		while( trys <= list_length )
		{
			console.log('IS IN');
			let x = Math.floor( Math.random() * list_length );

			console.log('Tryin here');

			if( selected.includes(x) )
			{
				console.log('Fails miserably');
				continue;
			}
			trys++;

			selected.push(x);
			let p = this.getSelectedPercentage(selected);

			if( p >= this.percentage_to_select )
			{
				break;
			}

			//Si se pasa de la cantidad de elementos
			//Entonces tronamos
		}
		console.log('IS OUT');

		for(let i of selected)
		{
			this.order_info_list[i].checked = true;
		}

		this.updateSelectedTotal();
	}

	getSelectedPercentage(indexes:number[]):number
	{
		let total = 0;
		let total_selected = 0;

		let index = 0;

		for(let oi of this.order_info_list)
		{
			total += oi.order.total-oi.order.discount;

			if( indexes.includes(index) )
			{
				total_selected += oi.order.total-oi.order.discount;
			}
			index++;
		}

		console.log('total_selected',total_selected/total*100);

		return (total_selected/total*100);
	}
}
