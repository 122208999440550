import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { environment } from '../environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';

import { HttpClientModule } from '@angular/common/http';
import { HttpClientJsonpModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularDateHttpInterceptor } from './services/AngularDateHttpInterceptor'
import { FormsModule } from '@angular/forms';
import { RestService } from 'src/app/services/rest.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AttachmentUploaderComponent } from './components/attachment-uploader/attachment-uploader.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MenuComponent } from './components/menu/menu.component';
import { ModalComponent } from './components/modal/modal.component';
import { ToastErrorComponent } from './components/toast-error/toast-error.component';
import { BaseComponent } from './pages/base/base.component';
import { HomeComponent } from './pages/home/home.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ListStoreComponent } from './pages/list-store/list-store.component';
import { ListCategoryComponent } from './pages/list-category/list-category.component';
import { ListItemComponent } from './pages/list-item/list-item.component';
import { ListOrderComponent } from './pages/list-order/list-order.component';
import { ListRequisitionComponent } from './pages/list-requisition/list-requisition.component';
import { ListUserComponent } from './pages/list-user/list-user.component';
import { LoginComponent } from './pages/login/login.component';
import { SaveCategoryComponent } from './pages/save-category/save-category.component';
import { SaveItemComponent } from './pages/save-item/save-item.component';
import { SavePreferencesComponent } from './pages/save-preferences/save-preferences.component';
import { SaveRequisitionComponent } from './pages/save-requisition/save-requisition.component';
import { SaveStoreComponent } from './pages/save-store/save-store.component';
import { SaveUserComponent } from './pages/save-user/save-user.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ListClientComponent } from './pages/list-client/list-client.component';
import { SaveClientComponent } from './pages/save-client/save-client.component';
import { ListStockComponent } from './pages/list-stock/list-stock.component';
import { QrCodeScannerComponent } from './components/qr-code-scanner/qr-code-scanner.component';
import { SavePriceTypeComponent } from './pages/save-price-type/save-price-type.component';
import { ListPriceTypeComponent } from './pages/list-price-type/list-price-type.component';
import { SearchItemsComponent } from './components/search-items/search-items.component';
import { ViewOrderComponent } from './pages/view-order/view-order.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EditPricesComponent } from './edit-prices/edit-prices.component';
import { EasyPosComponent } from './custom_pages/easy-pos/easy-pos.component';
import { AdjustStockComponent } from './pages/adjust-stock/adjust-stock.component';
import { PayOrderComponent } from './components/pay-order/pay-order.component';
import { StockReportComponent } from './pages/stock-report/stock-report.component';
import { MakePaymentComponent } from './components/make-payment/make-payment.component';
import { PrintReceiptComponent } from './pages/print-receipt/print-receipt.component';
import { ListPaymentComponent } from './pages/list-payment/list-payment.component';
import { MarkedPipe } from './pipes/marked.pipe';
import { AddNewClientComponent } from './components/add-new-client/add-new-client.component';
import { ListCashCloseComponent } from './components/list-cash-close/list-cash-close.component';
import { PrintCashClosingComponent } from './pages/print-cash-closing/print-cash-closing.component';
import { PrintCashClosing2Component } from './pages/print-cash-closing2/print-cash-closing2.component';
import { SaveCashCloseComponent } from './components/save-cash-close/save-cash-close.component';
import { SaveFundComponent } from './components/save-fund/save-fund.component';
//import { SaveBankMovementEmbededComponent } from './components/save-bank-movement-embeded/save-bank-movement-embeded.component';
import { SaveBillPaymentComponent } from './pages/save-bill-payment/save-bill-payment.component';
import { AddPosBillComponent } from './components/add-pos-bill/add-pos-bill.component';
import { SelectItemOptionComponent } from './components/select-item-option/select-item-option.component';
import { ListCommandaComponent } from './pages/list-commanda/list-commanda.component';
import { SaveCommandaComponent } from './pages/save-commanda/save-commanda.component';
import { ViewCommandaComponent } from './pages/view-commanda/view-commanda.component';
import { OrderItemProductFilterPipe } from './pipes/order-item-product-filter.pipe';
import { SaveOrderFacturaComponent } from './pages/save-order-factura/save-order-factura.component';
import { ListBillingDataComponent } from './pages/list-billing-data/list-billing-data.component';
import { SaveBillingDataComponent } from './pages/save-billing-data/save-billing-data.component';
import { SplitOrderComponent } from './pages/split-order/split-order.component';
import { SaveStockRecordComponent } from './pages/save-stock-record/save-stock-record.component';
import { ListPriceListComponent } from './pages/list-price-list/list-price-list.component';
import { SavePriceListComponent } from './pages/save-price-list/save-price-list.component';
import { ListAddressComponent } from './pages/list-address/list-address.component';
import { SaveAddressComponent } from './pages/save-address/save-address.component';
import { ListCommandaTypeComponent } from './pages/list-commanda-type/list-commanda-type.component';
import { SaveCommandaTypeComponent } from './pages/save-commanda-type/save-commanda-type.component';
import { SavePurchaseComponent } from './pages/save-purchase/save-purchase.component';
import { ListPurchaseComponent } from './pages/list-purchase/list-purchase.component';
import { SaveStorageComponent } from './pages/save-storage/save-storage.component';
import { ListStorageComponent } from './pages/list-storage/list-storage.component';
import { ListMermaComponent } from './pages/list-merma/list-merma.component';
import { AddNewAddressComponent } from './components/add-new-address/add-new-address.component';
import { DeliveryOrdersComponent } from './pages/delivery-orders/delivery-orders.component';
import { DeliveryOrderMapComponent } from './pages/delivery-order-map/delivery-order-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ClientPosComponent } from './client/client-pos/client-pos.component';
import { ClientAddressComponent } from './client/client-address/client-address.component';
import { ClientOrdersComponent } from './client/client-orders/client-orders.component';
import { ClientViewOrderComponent } from './client/client-view-order/client-view-order.component';
import { ClientSignupComponent } from './client/client-signup/client-signup.component';
import { ClientSaveAddressComponent } from './client/client-save-address/client-save-address.component';
import { ClientSelectCategoryItemComponent } from './client/client-select-category-item/client-select-category-item.component';
import { ClientSelectCategoryComponent } from './client/client-select-category/client-select-category.component';
import { FullTextComponent } from './components/full-text/full-text.component';
import { ClientConfigureSelectedItemComponent } from './client/client-configure-selected-item/client-configure-selected-item.component';
import { ClientCartComponent } from './client/client-cart/client-cart.component';
import { SelectDeliveryUserComponent } from './components/select-delivery-user/select-delivery-user.component';
import { CCartComponent } from './client/c-cart/c-cart.component';
import { OrdersReportComponent } from './pages/orders-report/orders-report.component';
import { DashboardUserComponent } from './pages/dashboard-user/dashboard-user.component';
import { CDashboardComponent } from './client/c-dashboard/c-dashboard.component';
import { CConfirmOrderComponent } from './client/c-confirm-order/c-confirm-order.component';
import { CLoginComponent } from './client/c-login/c-login.component';
import { ClientChangeStoreComponent } from './client/client-change-store/client-change-store.component';
import { ClientSelectMapAddressComponent } from './client/client-select-map-address/client-select-map-address.component';
import { ClientFacturarComponent } from './client/client-facturar/client-facturar.component';
import { ListPriceComponent } from './pages/list-price/list-price.component';
import { ListCategoryPriceComponent } from './pages/list-category-price/list-category-price.component';
import { ListShippingComponent } from './pages/list-shipping/list-shipping.component';
import { SaveShippingComponent } from './pages/save-shipping/save-shipping.component';
import { ReceiveShippingComponent } from './pages/receive-shipping/receive-shipping.component';
import { ViewShippingComponent } from './pages/view-shipping/view-shipping.component';
import { ReportClientsComponent } from './pages/report-clients/report-clients.component';
import { ReportSellsByPriceComponent } from './pages/report-sells-by-price/report-sells-by-price.component';
import { ReportSellsByCategoryComponent } from './pages/report-sells-by-category/report-sells-by-category.component';
import { ReportSellsByItemComponent } from './pages/report-sells-by-item/report-sells-by-item.component';
import { AddClientPaymentComponent } from './pages/add-client-payment/add-client-payment.component';
import { PrintPaymentReceiptComponent } from './pages/print-payment-receipt/print-payment-receipt.component';
import { ListOrderWaiterComponent } from './pages/list-order-waiter/list-order-waiter.component';
import { MakeOrderReturnComponent } from './components/make-order-return/make-order-return.component';
import { ListReturnsComponent } from './pages/list-returns/list-returns.component';
import { PrintReturnReceiptComponent } from './pages/print-return-receipt/print-return-receipt.component';
import { ItemSelectorComponent } from './components/item-selector/item-selector.component';
import { PrintExpensePaymentComponent } from './pages/print-expense-payment/print-expense-payment.component';
import { ListPaymentExpensesComponent } from './pages/list-payment-expenses/list-payment-expenses.component';
import { OfflineResumeComponent } from './pages/offline-resume/offline-resume.component';
import { AddNewItemEasyComponent } from './components/add-new-item-easy/add-new-item-easy.component';
import { SearchCategoryComponent } from './components/search-category/search-category.component';
import { ViewPurchaseComponent } from './pages/view-purchase/view-purchase.component';
import { MakePaymentMobileComponent } from './pages/make-payment-mobile/make-payment-mobile.component';
import { SaveQuoteComponent } from './pages/save-quote/save-quote.component';
import { ListQuoteComponent } from './pages/list-quote/list-quote.component';
import { ViewQuoteComponent } from './pages/view-quote/view-quote.component';
import { PrintRemissionComponent } from './pages/print-remission/print-remission.component';
import { PrintQuoteComponent } from './pages/print-quote/print-quote.component';
import { FacturacionPeriodoComponent } from './pages/facturacion-periodo/facturacion-periodo.component';
import { StockSearchReportComponent } from './pages/stock-search-report/stock-search-report.component';
import { ListStockRecordComponent } from './pages/list-stock-record/list-stock-record.component';
import { TopTenComponent } from './pages/top-ten/top-ten.component';
import { ItemsWithoutPriceComponent } from './src/app/pages/items-without-price/items-without-price.component';
import { ListTableComponent } from './pages/list-table/list-table.component';
import { SaveTableComponent } from './pages/save-table/save-table.component';
import { TablesComponent } from './pages/tables/tables.component';
import { ListPriceLogComponent } from './pages/list-price-log/list-price-log.component';
import { PrintCommandaComponent } from './pages/print-commanda/print-commanda.component';
import { WeirdComponent } from './pages/weird/weird.component';
import { PrintOrderPaymentComponent } from './page/print-order-payment/print-order-payment.component';
import { SaveCategoryStoreComponent } from './pages/save-category-store/save-category-store.component';
import { CorteXFolioComponent } from './pages/corte-x-folio/corte-x-folio.component';
//import { ReportOrderWithDiscountsComponent } from './pages/report-order-with-discounts/report-order-with-discounts.component';
import { SalesReportComponent } from './pages/sales-report/sales-report.component';
import { CreateSalesReportComponent } from './pages/create-sales-report/create-sales-report.component';
import { ViewSalesReportComponent } from './pages/view-sales-report/view-sales-report.component';
import { ListSalesReportComponent } from './pages/list-sales-report/list-sales-report.component';
import { ReportClientRefundsComponent } from './pages/report-client-refunds/report-client-refunds.component';
import { SaveProviderComponent } from './pages/save-provider/save-provider.component';
import { ListProviderComponent } from './pages/list-provider/list-provider.component';
import { HelpComponent } from './pages/help/help.component';
import { ListCategoryStoreComponent } from './pages/pages/list-category-store/list-category-store.component';
import { SaveItemStoreComponent } from './pages/save-item-store/save-item-store.component';
import { ShortDatePipe } from './pipes/short-date.pipe';
import { PrintReceipt2Component } from './pages/print-receipt2/print-receipt2.component';
import { ListOfferComponent } from './pages/list-offer/list-offer.component';
import { SaveOfferComponent } from './pages/save-offer/save-offer.component';
import { TableOrderComponent } from './client/table-order/table-order.component';
import { SavePostComponent } from './pages/save-post/save-post.component';
import { ListPostComponent } from './pages/list-post/list-post.component';
import { PostComponent } from './pages/post/post.component';
import { ErrorTableComponent } from './client/error-table/error-table.component';
import { PrintReceipt3Component } from './pages/print-receipt3/print-receipt3.component';
import { SelectItemSerialComponent } from './components/select-item-serial/select-item-serial.component';
import { SaveSerialComponent } from './pages/save-serial/save-serial.component';
import { PrintReceipt5Component } from './pages/print-receipt5/print-receipt5.component';
import { TicketStringPipe } from './pipes/ticket-string.pipe';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { CancelledOrdersComponent } from './pages/cancelled-orders/cancelled-orders.component';
import { AffiliateSalesComponent } from './pages/affiliate-sales/affiliate-sales.component';
import { MaxminReportComponent } from './pages/maxmin-report/maxmin-report.component';
import { FacturarOrdenesFromClientComponent } from './pages/facturar-ordenes-from-client/facturar-ordenes-from-client.component';
import { ListUserOrderComponent } from './pages/list-user-order/list-user-order.component';
import { MakePaymentOrdersComponent } from './components/make-payment-orders/make-payment-orders.component';
import { IncomeTypeReportComponent } from './pages/income-type-report/income-type-report.component';
import { PrintReceipt6Component } from './pages/print-receipt6/print-receipt6.component';
import { PrintCashClosing3Component } from './pages/print-cash-closing3/print-cash-closing3.component';
import { ItemNamePipe } from './pipes/item-name.pipe';
import { ItemInSalesReportComponent } from './pages/item-in-sales-report/item-in-sales-report.component';
import { PhonePipe } from './pipes/phone.pipe';
import { ReportItemBomPriceComponent } from './pages/report-item-bom-price/report-item-bom-price.component';
import { ReportUtilityComponent } from './pages/report-utility/report-utility.component';
import { ReportOrderItemProfitComponent } from './pages/report-order-item-profit/report-order-item-profit.component';
import { ReportsOccupancyComponent } from './pages/reports-occupancy/reports-occupancy.component';
import { ReportsItemProfitComponent } from './pages/reports-item-profit/reports-item-profit.component';
import { ReportWaiterOrdersComponent } from './pages/report-waiter-orders/report-waiter-orders.component';
import { ReportTicketAvgComponent } from './pages/report-ticket-avg/report-ticket-avg.component';
import { ReportOccupancyComponent } from './pages/report-occupancy/report-occupancy.component';
import { ViewPaymentComponent } from './pages/view-payment/view-payment.component';
import { PrintShippingComponent } from './pages/print-shipping/print-shipping.component';
import { SaveCurrencyRateComponent } from './pages/save-currency-rate/save-currency-rate.component';
import { SaveStocktakeComponent } from './pages/save-stocktake/save-stocktake.component';
import { ListStocktakeComponent } from './pages/list-stocktake/list-stocktake.component';
import { ViewStocktakeComponent } from './pages/view-stocktake/view-stocktake.component';
import { NewOrderComponent } from './client/new-order/new-order.component';
import { ViewRequisitionComponent } from './pages/view-requisition/view-requisition.component';
import { ListFormComponent } from './pages/list-form/list-form.component';
import { SaveFormComponent } from './pages/save-form/save-form.component';
import { ListResponseComponent } from './pages/list-response/list-response.component';
import { SaveResponseComponent } from './pages/save-response/save-response.component';
import { SearchUsersComponent } from './components/search-users/search-users.component';
import { ViewClientComponent } from './pages/view-client/view-client.component';
import { ListProviderBillsComponent } from './pages/list-provider-bills/list-provider-bills.component';
import { MakePaymentBillsComponent } from './components/make-payment-bills/make-payment-bills.component';
import { SearchSerialComponent } from './pages/search-serial/search-serial.component';
import { ListBillComponent } from './pages/list-bill/list-bill.component';
import { SaveSerialBatchComponent } from './pages/save-serial-batch/save-serial-batch.component';
import { SatxmlviewerComponent } from './components/satxmlviewer/satxmlviewer.component';
import { ListOrderFacturasComponent } from './pages/list-order-facturas/list-order-facturas.component';
import { ListSatFacturaComponent } from './pages/list-sat-factura/list-sat-factura.component';
import { SaldoClienteComponent } from './pages/saldo-cliente/saldo-cliente.component';
import { ListClientOrdersComponent } from './pages/list-client-orders/list-client-orders.component';
import { FacturaMultipleComponent } from './pages/factura-multiple/factura-multiple.component';
import { ReportSellsByProductionAreaComponent } from './pages/report-sells-by-production-area/report-sells-by-production-area.component';
import { ViewSalesProductionAreaComponent } from './pages/view-sales-production-area/view-sales-production-area.component';
import { PrintCancellationItemReceiptComponent } from './pages/print-cancellation-item-receipt/print-cancellation-item-receipt.component';
import { ListReturnedItemsComponent } from './pages/list-returned-items/list-returned-items.component';
import { ListAttributeComponent } from './pages/list-attribute/list-attribute.component';
import { SaveAttributeComponent } from './pages/save-attribute/save-attribute.component';
import { ComexClientComponent } from './components/comex-client/comex-client.component';
// import { ListReportsComponent } from './pages/list-reports/list-reports.component';
//import { NgxKjuaModule } from 'ngx-kjua';


export function init_app(rest: RestService)
{
	return async ()=>
	{
		if( window.location.protocol == 'http:' )
		{
			//if( window.location.hostname.includes('integranet.xyz') )
			//{
			//	location.replace(`https:${location.href.substring(location.protocol.length)}`);
			//}
		}

		return rest.getPreferencesInfo().catch((error)=>
		{
			let preferences= localStorage.getItem('preferences');
			if( !preferences)
				throw 'Preferences not found';
			return Promise.resolve( JSON.parse( preferences ) );
		});
	};
}

@NgModule({
	declarations: [
		AppComponent,
		BaseComponent,
		HomeComponent,
		LoginComponent,
		DashboardComponent,
		AttachmentUploaderComponent,
		HeaderComponent,
		ImageUploaderComponent,
		LoadingComponent,
		MenuComponent,
		ModalComponent,
		ToastErrorComponent,
		BaseComponent,
		HomeComponent,
		DashboardComponent,
		ListStoreComponent,
		ListCategoryComponent,
		ListItemComponent,
		ListOrderComponent,
		ListRequisitionComponent,
		ListOfferComponent,
		SaveOfferComponent,
		ListUserComponent,
		LoginComponent,
		SaveCategoryComponent,
		SaveItemComponent,
		SavePreferencesComponent,
		SaveRequisitionComponent,
		SaveStoreComponent,
		SaveUserComponent,
		PaginationComponent,
		ListClientComponent,
		SaveClientComponent,
		ListStockComponent,
		QrCodeScannerComponent,
		SavePriceTypeComponent,
		ListPriceTypeComponent,
		SearchItemsComponent,
		ViewOrderComponent,
		EditPricesComponent,
		EasyPosComponent,
		AdjustStockComponent,
		PayOrderComponent,
		StockReportComponent,
		MakePaymentComponent,
		PrintReceiptComponent,
		PrintReceipt2Component,
		ListPaymentComponent,
		MarkedPipe,
		ShortDatePipe,
	  ItemNamePipe,
		AddNewClientComponent,
		ListCashCloseComponent,
		PrintCashClosingComponent,
		SaveCashCloseComponent,
		SaveFundComponent,
//		SaveBankMovementEmbededComponent,
		SaveBillPaymentComponent,
		AddPosBillComponent,
		SelectItemOptionComponent,
		ListCommandaComponent,
		SaveCommandaComponent,
		ViewCommandaComponent,
		OrderItemProductFilterPipe,
		SaveOrderFacturaComponent,
		ListBillingDataComponent,
		SaveBillingDataComponent,
		SplitOrderComponent,
		SaveStockRecordComponent,
		ListPriceListComponent,
		SavePriceListComponent,
		ListAddressComponent,
		SaveAddressComponent,
		ListCommandaTypeComponent,
		SaveCommandaTypeComponent,
		SavePurchaseComponent,
		ListPurchaseComponent,
		SaveStorageComponent,
		ListStorageComponent,
		ListMermaComponent,
		AddNewAddressComponent,
		DeliveryOrdersComponent,
		DeliveryOrderMapComponent,
		ClientPosComponent,
		ClientAddressComponent,
		ClientOrdersComponent,
		ClientViewOrderComponent,
		ClientSignupComponent,
		ClientSaveAddressComponent,
		ClientSelectCategoryItemComponent,
		ClientSelectCategoryComponent,
		FullTextComponent,
		ClientConfigureSelectedItemComponent,
		ClientCartComponent,
		SelectDeliveryUserComponent,
		CCartComponent,
		OrdersReportComponent,
		DashboardUserComponent,
		CDashboardComponent,
		CConfirmOrderComponent,
		CLoginComponent,
		ClientChangeStoreComponent,
		ClientSelectMapAddressComponent,
		ClientFacturarComponent,
		ListPriceComponent,
		ListCategoryPriceComponent,
		ListShippingComponent,
		SaveShippingComponent,
		ReceiveShippingComponent,
		ViewShippingComponent,
		ReportClientsComponent,
		ReportSellsByPriceComponent,
		ReportSellsByCategoryComponent,
		ReportSellsByItemComponent,
		AddClientPaymentComponent,
		PrintPaymentReceiptComponent,
		ListOrderWaiterComponent,
		MakeOrderReturnComponent,
		ListReturnsComponent,
		PrintReturnReceiptComponent,
		ItemSelectorComponent,
		PrintExpensePaymentComponent,
		ListPaymentExpensesComponent,
		OfflineResumeComponent,
		AddNewItemEasyComponent,
		SearchCategoryComponent,
		ViewPurchaseComponent,
		MakePaymentMobileComponent,
		SaveQuoteComponent,
		ListQuoteComponent,
		ViewQuoteComponent,
		PrintRemissionComponent,
		PrintQuoteComponent,
		FacturacionPeriodoComponent,
		StockSearchReportComponent,
		ListStockRecordComponent,
		PrintCashClosing2Component,
		TopTenComponent,
		ItemsWithoutPriceComponent,
		ListTableComponent,
		SaveTableComponent,
		TablesComponent,
		ListPriceLogComponent,
		PrintCommandaComponent,
		WeirdComponent,
		PrintOrderPaymentComponent,
		SaveCategoryStoreComponent,
		CorteXFolioComponent,
		//ReportOrderWithDiscountsComponent,
		SalesReportComponent,
		CreateSalesReportComponent,
		ViewSalesReportComponent,
		ListSalesReportComponent,
		ReportClientRefundsComponent,
		SaveProviderComponent,
		ListProviderComponent,
		HelpComponent,
		ListCategoryStoreComponent,
		SaveItemStoreComponent,
		TableOrderComponent,
		SavePostComponent,
		ListPostComponent,
		PostComponent,
		ErrorTableComponent,
		PrintReceipt3Component,
		SelectItemSerialComponent,
		SaveSerialComponent,
		PrintReceipt5Component,
		TicketStringPipe,
		CancelledOrdersComponent,
		AffiliateSalesComponent,
		MaxminReportComponent,
		FacturarOrdenesFromClientComponent,
		ListUserOrderComponent,
		MakePaymentOrdersComponent,
		IncomeTypeReportComponent,
		PrintReceipt6Component,
		PrintCashClosing3Component,
    	ItemInSalesReportComponent,
		PhonePipe,
    	ReportItemBomPriceComponent,
    	ReportUtilityComponent,
    	ReportOrderItemProfitComponent,
    	ReportsOccupancyComponent,
    	ReportsItemProfitComponent,
    	ReportWaiterOrdersComponent,
    	ReportTicketAvgComponent,
    	ReportOccupancyComponent,
    	ViewPaymentComponent,
    	PrintShippingComponent,
    	SaveCurrencyRateComponent,
     	SaveStocktakeComponent,
      ListStocktakeComponent,
      ViewStocktakeComponent,
      NewOrderComponent,
      ViewRequisitionComponent,
      ListFormComponent,
      SaveFormComponent,
      ListResponseComponent,
      SaveResponseComponent,
	  SearchUsersComponent,
   ViewClientComponent,
   ListProviderBillsComponent,
   MakePaymentBillsComponent,
   SearchSerialComponent,
   ListBillComponent,
   SaveSerialBatchComponent,
   SatxmlviewerComponent,
   ListOrderFacturasComponent,
   ListSatFacturaComponent,
   SaldoClienteComponent,
   ListClientOrdersComponent,
   FacturaMultipleComponent,
   ReportSellsByProductionAreaComponent,
   ViewSalesProductionAreaComponent,
   PrintCancellationItemReceiptComponent,
   ListReturnedItemsComponent,
   ListAttributeComponent,
   SaveAttributeComponent,
   ComexClientComponent,
//    ListReportsComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		HttpClientJsonpModule,
		FormsModule,
		GoogleMapsModule,
		ScrollingModule,
		AngularFireAuthModule,
		AngularFireMessagingModule,
		AngularFireModule.initializeApp(environment.firebase),
		NgxChartsModule,
		NgxQRCodeModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production,
		// Register the ServiceWorker as soon as the app is stable
		// or after 30 seconds (whichever comes first).
		registrationStrategy: 'registerImmediately'
	})
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AngularDateHttpInterceptor, multi: true },
		{ provide: APP_INITIALIZER, useFactory: init_app, deps: [RestService], multi: true },
],
	bootstrap: [AppComponent]
})
export class AppModule { }
