import { OnChanges, SimpleChanges } from '@angular/core';
import { Component, OnInit, Input} from '@angular/core';
import { BaseComponent } from '../../pages/base/base.component';
import { RestService } from '../../services/rest.service';


//Asi de culero esta en el ws, vamos a poner este tal cual
interface ComexClient
{
	loginName:string; //Numero de tarjeta
	password:string; //
	//userType:string;
	//loginLocation:string;
	nombre:string;
	apellidoPaterno:string;
	apellidoMaterno:string;
	estadoCivil:string;
	eMail:string;
	pais:string;
	sexo:string;
	fechaNacimiento:string;
	telefonoCasa:string;
	telefonoOficina:string;
	celular:string;
	calle:string;
	numeroExterior:string;
	numeroInterior:string;
	colonia:string;
	municipio:string;
	estado:string;
	ciudad:string;
	codigoPostal:string;
	positionTitle:string;
	ocupacion:string;
	otraOcupacion:string;
	compania:string;
	activationKey:string;
	externalId:string;
	contactoTel:string;
	contactoMail:string;
	contactoDireccion:string;
	Operatel:string;
	rfc:string;
	client_user_id:number;
}


@Component({
	selector: 'app-comex-client',
	templateUrl: './comex-client.component.html',
	styleUrls: ['./comex-client.component.css']
})
export class ComexClientComponent extends BaseComponent implements OnInit, OnChanges
{
	@Input() client_user_id:number = 0;

	comex_client:ComexClient = this.getEmptyComexClient();

	ngOnInit(): void
	{

	}

	ngOnChanges(changes: SimpleChanges): void
	{
		console.log('Changes', changes );
    }

	getEmptyComexClient():ComexClient
	{
		return {
			loginName:'', //Numero de tarjeta
			password:'', //
			nombre:'',
			apellidoPaterno:'',
			apellidoMaterno:'',
			estadoCivil:'',
			eMail:'',
			pais:'',
			sexo:'',
			fechaNacimiento:'',
			telefonoCasa:'',
			telefonoOficina:'',
			celular:'',
			calle:'',
			numeroExterior:'',
			numeroInterior:'',
			colonia:'',
			municipio:'',
			estado:'',
			ciudad:'',
			codigoPostal:'',
			positionTitle:'',
			ocupacion:'',
			otraOcupacion:'',
			compania:'',
			activationKey:'',
			externalId:'',
			contactoTel:'',
			contactoMail:'',
			contactoDireccion:'',
			Operatel:'',
			rfc:'',
			client_user_id:this.client_user_id
		}
	}


	save(evt:Event)
	{
		evt.preventDefault();
		console.log("Guardando...");
		this.is_loading = true;

		this.comex_client.client_user_id = this.client_user_id;

		this.sink = this.rest.comex('user', this.comex_client).subscribe
		({
			next: (data:any)=>
			{
				console.log(data);
				this.is_loading = false;
			},
			error: (error:any)=>
			{
				console.log(error);
				this.is_loading = false;
				this.showError( error );
			}
		});
	}
}
