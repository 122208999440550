<div class="container-fluid">
	<div class="row align-items-center mx-0">
		<div class="col">
			<h1 class="my-3 text-capitalize">Mesas</h1>
		</div>
		<div class="col-auto px-0 text-end">
			<button type="button" class="ml-2 btn btn-secondary" [routerLink]="['/add-attribute']"> Agregar Atributo</button>
		</div>
	</div>

	<div>
		<div class="row">
			<div class="col-6 col-md-3 form-group">
				<label class="">Nombre</label>
				<input type="text" name="name" [(ngModel)]="attribute_search.lk.name" class="form-control">
			</div>
			<div class="col-6 col-md-3">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="w-100 btn btn-primary" (click)="search()">Buscar</button>
				</div>
			</div>
		</div>
	</div>

	<div  class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="attribute-responsive">
			<table class="w-100 table table-sm attribute-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>id</th>
						<th>nombre</th>
						<th>Fecha Creacion</th>
						<th>Fecha actualizacion</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let attribute of attribute_list">
						<td>{{attribute.id}}</td>
						<td>
							<a [routerLink]="['/edit-attribute',attribute.id]">{{attribute.name}}</a>
						</td>
						<td>{{attribute.created| shortDate: 'relative' }}</td>
						<td>{{attribute.updated | shortDate: 'relative' }}</td>
					</tr>
					<tr *ngIf="attribute_list.length == 0 && !is_loading" colspan="TEMPLATE_HEADERS_SIZE">
						<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="'/list-attribute'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>

