<div class="container-fluid">
	<div class="row align-items-center my-3 mx-0">
		<div class="col">
			<h1>{{ list_user_title }}</h1>
		</div>

		<div class="col-auto">
			<!-- button type="button" class="mr-2 btn btn-secondary lift" (click)="show_import=true">Importar</button>
			<button type="button" class="mr-2 btn btn-secondary lift" (click)="exportFile()">Exportar</button -->
			<a class="btn btn-secondary lift" [routerLink]="['/user']">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M4.5 8.552c0 1.995 1.505 3.5 3.5 3.5s3.5-1.505 3.5-3.5s-1.505-3.5-3.5-3.5s-3.5 1.505-3.5 3.5zM19 8h-2v3h-3v2h3v3h2v-3h3v-2h-3zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1h2z" fill="currentColor"/></svg>
				Agregar
			</a>
		</div>
	</div>

	<form (submit)="search(user_search)" name="search_client">
		<div class="p-3 card my-3">
			<div class="row">
				<div class="col-8 col-md-5 form-group">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="user_search.lk.name" class="form-control">
				</div>
				<div class="col-8 col-md-5 form-group">
					<label class="">Sucursal</label>
					<select name="store_id" [(ngModel)]="user_search.eq.store_id" class="form-control">
						<option [ngValue]="null">Todas</option>
						<option *ngFor="let store of store_list" [value]="store.id">{{store.name}}</option>
					</select>
				</div>
				<div class="col-6 col-md-2 form-group">
					<label class="">&nbsp;</label>
					<button type="submit" class="w-100 btn btn-primary">Buscar</button>
				</div>

			</div>
		</div>
	</form>
	<div class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th class="pointer" (click)="this.sort('name',this.user_search)">Usuario</th>
						<th>Sucursal</th>
						<th class="text-end pointer" (click)="this.sort('updated',this.user_search)">Actualizado</th>
						<th class="text-end">Acciones</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let user of user_list">
						<td>
							<img *ngIf="user.user.image_id" [src]="rest.getImagePath(user.user.image_id)" style="max-width:30px;max-height:30px;">
							<span *ngIf="!user.user.image_id" class="default_image">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path _ngcontent-tvn-c138="" d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"></path></svg>
							</span>
							&nbsp;
							<a [routerLink]="['/user',user.user.id]" *ngIf="rest.current_permission.add_user">{{user.user.name}}</a>
							<span *ngIf="!rest.current_permission.add_user">{{user.user.name}}</span>
						</td>
						<td>{{user?.store?.name}}</td>
						<td class="text-end">{{user.user.updated | shortDate }}</td>
						<td class="text-end">
							<ng-container *ngIf="rest.current_permission.reports">
								<a [routerLink]="['/list-cash-close',user.user.id]" class="btn btn-primary">Cortes</a>
							</ng-container>
							<button (click)="deleteUser(user)" class="ms-1 btn btn-danger" *ngIf="rest.current_permission.add_user">Eliminar</button>
						</td>
					</tr>
					<tr *ngIf="user_list.length == 0 && !is_loading">
						<td colspan="4">
							<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="'/list-user'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
