import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { forkJoin,of } from 'rxjs';

import {Item, Serial, Stock_Record, Store} from '../../models/RestModels';

import {Category} from '../../models/RestModels';
import {ShippingInfo, ShippingItemInfo, ItemStockInfo, RequisitionInfo, BoxContentInfo, BoxInfo, ItemInfo, SerialItemInfo, RequisitionItemInfo } from '../../models/models';
import {mergeMap} from 'rxjs/operators';
import { SearchObject } from 'src/app/services/Rest';

//interface ShippingItemTotal extends ShippingItemInfo
//{
//
//}

@Component({
	selector: 'app-save-shipping',
	templateUrl: './save-shipping.component.html',
	styleUrls: ['./save-shipping.component.css']
})
export class SaveShippingComponent extends BaseComponent implements OnInit
{

	store_list:Store[] = [];

	shipping_info:ShippingInfo = {
		shipping: {},
		items: []
	}
	cameras_found = true;
	type:string = null;
	requisition_info:RequisitionInfo = null;

	item_dictionary:Record<number,ItemStockInfo> = {};
	category_dictionary:Record<number,Category> = {};
	selected_category_id:number = null;
	item_list:ItemStockInfo[];
	category_list:Category[] = [];
	selected_item_id:number = null;
	qty_by_item_id:Record<number,number> = {};

	from_store_id:number | string = '';
	to_store_id:number | string = '';
	serial_search: string = '';
	tmp_serial_list: SerialItemInfo[] = [];
	serial_list:SerialItemInfo[] = [];
	show_serial_numbers: boolean;
	selected_item_info: ItemInfo;

	ngOnInit()
	{
		this.subs.sink = this.route.paramMap.subscribe( params =>
		{
			//this.company = this.rest.getCompanyFromSession();
			this.cameras_found = true;
			this.is_loading = true;

			let empty:ShippingInfo = {
				shipping: {
					from_store_id: this.rest.current_user.store_id,
					to_store_id: null,
					status: 'PENDING',
				},
				items: [],
			};
			this.subs.sink = forkJoin
			({
				shipping_info : params.has('id') ? this.rest.shipping_info.get( params.get('id')	): of( empty ),
				store : this.rest.store.search({limit:9999}),
				category: this.rest.category.search({limit:99999,sort_order:['name_ASC']})
			})
			.pipe
			(
				mergeMap((response)=>
				{

					this.from_store_id = '';
					this.to_store_id = '';

					let requisition_id:number = params.has('requisition_id')	? parseInt(params.get('requisition_id')) : null;

					if( response.shipping_info.shipping?.requisition_id )
						requisition_id = response.shipping_info.shipping?.requisition_id;

					response.shipping_info.shipping.requisition_id = requisition_id;

					console.log('requisition id',requisition_id);

					return forkJoin
					({
						shipping_info: of( response.shipping_info ),
						store: of( response.store),
						category: of( response.category ),
						requisition: requisition_id ? this.rest.requisition_info.get(requisition_id) : of( null )
					})
				})
			)
			.subscribe((responses)=>
			{
				this.is_loading = false;
				responses.category.data.forEach(c=>this.category_dictionary[c.id]=c);
				this.store_list = responses.store.data;
				this.shipping_info = responses.shipping_info;

				this.requisition_info = responses.requisition;

				this.from_store_id = this.shipping_info.shipping.from_store_id || '';
				this.to_store_id = this.shipping_info.shipping.to_store_id || '';

				if( !this.from_store_id )
				{
					this.from_store_id = this.requisition_info?.requisition?.requested_to_store_id || '';
					this.shipping_info.shipping.from_store_id = this.requisition_info.requisition.requested_to_store_id || null;
				}

				if( !this.to_store_id )
				{
					this.to_store_id = this.requisition_info?.requisition?.required_by_store_id || '';
					this.shipping_info.shipping.to_store_id = this.requisition_info?.requisition?.required_by_store_id || null;
				}

				this.shipping_info.items
				.forEach(i=>
				{
					i.shipping_item.received_qty = null;
				});

				this.requisition_info = responses.requisition;
				this.item_list = [];
				this.category_list = responses.category.data;
				console.log("requisition obj", responses.requisition);
			},(error)=>this.showError(error));
		});
	}

	onToStoreChange(store_id:number | '')
	{
		this.to_store_id = store_id;
		if( store_id === '')
		{
			console.log('Aqui que pedo xxx');
			this.shipping_info.shipping.to_store_id = null;
			return;
		}

		console.log('Aqui que pedo');
		this.shipping_info.shipping.to_store_id = store_id;
	}

	onFromStoreChange(store_id:number | '' )
	{
		this.from_store_id = store_id;

		if( store_id === '' )
		{
			console.log('QUE PASO AQUI', store_id );
			this.shipping_info.shipping.from_store_id = null;
			return;
		}

		console.log("LLEGA BIEN");
		this.shipping_info.shipping.from_store_id = store_id;
	}

	onCategoryChange(category_id:number)
	{
		//if( !category_id )
		//	this.item_list = [];
		//	return;
		//}

		//this.selected_category_id = category_id;
		//this.is_loading = true;
		//this.subs.sink = this.rest.item_stock.search
		//(
		//	{
		//		eq:{ category_id },
		//		limit:99999,
		//		sort_order:['name_ASC']
		//	},
		//	{
		//		store_id:this.shipping_info.shipping.from_store_id
		//	}
		//)
		//.subscribe((response)=>
		//{
		//	this.is_loading = false;
		//	this.item_list	= response.data;
		//},(error)=>this.showError(error));
	}

	addShippingItem(item_info:ItemInfo, qty:number = 1)
	{
		let shipping_item = this.shipping_info.items.find(i=>i.item.id == item_info.item.id);

		if( shipping_item !== undefined )
		{
			this.showError('El producto ya esta en la lista');
			return;
		}

		this.subs.sink = this.rest.item_stock.get( item_info.item.id )
		.subscribe((item_stock_info)=>
		{
			this.item_list.push(item_stock_info);

			let available:number = item_info.records.reduce((p,stock_record:Stock_Record)=>
			{
				if( stock_record.store_id == this.shipping_info.shipping.from_store_id )
					return stock_record.qty;
				return p;
			},0);

			this.shipping_info.items.push
			({
				item: item_stock_info.item,
				category:item_stock_info.category,
				shipping_item: { item_id: item_stock_info.item.id, qty,serial_number:'' },
				available: available
			});

			this.updateValues();
			this.allRequisitionItemsAdded();
		})
	}

	addRequistionItem(ri:RequisitionItemInfo)
	{
		this.subs.sink = this.rest.item_info
		.get( ri.item.id )
		.subscribe((response)=>{
			this.addShippingItem(response, ri.requisition_item.qty );

		},(error)=>this.showError(error));
	}

	addAllRequisitionItems() 
	{
		this.is_loading = true;
		if (!this.requisition_info || !this.requisition_info.items.length) 
		{
			this.showError('No hay artículos en la requisición.');
			return;
		}

		for (let ri of this.requisition_info.items) 
		{
			this.addRequistionItem(ri);
		}
	}

	allRequisitionItemsAdded()
	{
		if (!this.requisition_info || !this.requisition_info.items.length) 
		{
			return false;
		}

		for (let ri of this.requisition_info.items) 
		{
			if (!this.shipping_info.items.find(si => si.item.id == ri.item.id)) 
			{
				return false;
			}
		}

		this.is_loading = false;
		this.showSuccess('Todos los artículos de la requisición han sido agregados.');
		return true;
	}

	removeItem(si:Partial<ShippingItemInfo>)
	{
		let index:number = this.shipping_info.items.findIndex(i=>si==i)

		if( index > -1 )
		{
			this.shipping_info.items.splice(index,1);
		}
	}

	save(_evt:any)
	{
		this.is_loading = true;

		if( this.shipping_info.shipping?.id	)
		{
			this.shipping_info.shipping.received_by_user_id = this.rest.current_user.id;
			this.subs.sink	= this.rest.shipping_info.update( this.shipping_info )
			.subscribe((shipping)=>
			{
				this.is_loading = false;
				this.router.navigate(['/list-shipping']);
				this.showSuccess('El Envío se actualizo exitosamente');
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.shipping_info.create( this.shipping_info )
			.subscribe((shipping)=>
			{
				this.is_loading = false;
				this.showSuccess('El Envío se guardo exitosamente');
				this.router.navigate(['/list-shipping']);
			},(error)=>this.showError(error));
		}
	}

	updateValues():void
	{
		let counter:Record<number,number> = {};

		this.shipping_info.items.forEach((sii:ShippingItemInfo)=>
		{
			if( sii.pallet_info )
			{
				sii.pallet_info.content.forEach((bi:BoxInfo)=>{
					bi.content.forEach((bci:BoxContentInfo)=>{
						if( !(bci.box_content.item_id in counter) )
							counter[ bci.box_content.item_id ] = 0;

						counter[ bci.box_content.item_id ] = bci.box_content.qty;
					});
				})
			}
			else if( sii.box_info )
			{
				sii.box_info.content.forEach((bci:BoxContentInfo)=>
				{
					if( !(bci.box_content.item_id in counter) )
						counter[ bci.box_content.item_id ] = 0;

					counter[ bci.box_content.item_id ] = bci.box_content.qty;
				});
			}
			else	if( sii.shipping_item.item_id )
			{
				if( !(sii.shipping_item.item_id in counter ))
				{
					counter[ sii.shipping_item.item_id ] = 0;
				}
				counter[ sii.shipping_item.item_id ] = sii.shipping_item.qty;
			}
		});

		for(let i in counter)
		{
			this.qty_by_item_id[ i ] = counter[ i ];

		}
	}

	camerasFound(result:boolean)
	{
		this.cameras_found = result;
	}

	onItemSelected(item_info:ItemInfo):void
	{
		if( item_info.item.has_serial_number == 'NO' )
		{
			this.addShippingItem(item_info);
			return;
		}

		this.selected_item_info = item_info;

		if( !this.shipping_info.shipping.from_store_id )
		{
			this.showError('Por favor selecciona la sucursal origen para poder enviar Articulos con numeros de serie');
			return
		}

		let search_obj:SearchObject<Serial> =	this.getEmptySearch();


		search_obj.eq.item_id = item_info.item.id;
		search_obj.eq.store_id = this.shipping_info.shipping.from_store_id;
		search_obj.eq.status = 'ACTIVE';
		search_obj.limit = 99999999;

		console.log('Buscando', search_obj );

		this.subs.sink = this.rest.serial_info.search( search_obj )
		.subscribe((response)=>
		{
			this.serial_list = response.data.map((si)=>
			{
				return {
					serial_info: si,
					item_info: item_info
				};
			});
			this.tmp_serial_list = this.serial_list;

			this.show_serial_numbers = true;
		});
	}

	onSerialSearchChange(search:string)
	{
		this.serial_search = search;

		let s = search.toLowerCase();

		this.tmp_serial_list = this.serial_list.filter((ss)=>
		{
			if( this.shipping_info.items.some((x)=>x.shipping_item.serial_number == ss.serial_info.serial.serial_number) )
				return false;

			return ss.serial_info.serial.serial_number.toLowerCase().startsWith(s);
		});
	}

	addSerial(serial_number:string, item:Item, category:Category | null)
	{
		let index = this.tmp_serial_list.findIndex(ss=>ss.serial_info.serial.serial_number == serial_number);

		if( index > -1 )
		{
			this.tmp_serial_list.splice(index, 1 );

			this.shipping_info.items.push
			({
				item,
				shipping_item:{
					item_id: item.id,
					qty: 1,
					serial_number: serial_number
				},
				category
			});
		}

		if( this.tmp_serial_list.length == 0 )
		{
			this.show_serial_numbers = false;
		}
	}
}
