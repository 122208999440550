import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { SearchObject } from 'src/app/services/Rest';
import { Sat_Factura } from 'src/app/models/RestModels';
import { ParamMap } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

export interface CSatFacturaInfo extends Sat_Factura
{
	name_type:string;
	sat_cancelled:string;
	link: Array<any>;
	system_status: string;
}

@Component({
	selector: 'app-list-sat-factura',
	templateUrl: './list-sat-factura.component.html',
	styleUrls: ['./list-sat-factura.component.css']
})
export class ListSatFacturaComponent extends BaseComponent implements OnInit
{
	sat_factura_search: SearchObject<Sat_Factura> = this.getEmptySearch();
	sat_factura_info_list: CSatFacturaInfo[] = [];

	reenviar_factura_name:string = '';
	reenviar_factura_email:string = '';
	show_reenviar_factura:boolean = false;

	modal_response: string = '';
	modal_UUID: string = '';
	modal_factura_id: number | '';
	show_checar_factura: boolean = false;

	ngOnInit(): void
	{
		this.path = '/list-sat-factura';

		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((param_map: ParamMap) =>
			{
				this.setTitle('Reporte de facturas');
				this.is_loading = true;
				this.sat_factura_search = this.getSearch(param_map, []);
				this.sat_factura_search.limit = this.page_size;

				if (this.sat_factura_search.sort_order.length == 0)
				{
					this.sat_factura_search.sort_order = ['id_DESC'];
				}

				this.current_page = this.sat_factura_search.page;

				return forkJoin
				({
					param_map: of(param_map),
					sat_factura: this.rest.sat_factura.search(this.sat_factura_search)
				});
			}),
		)
		.subscribe((response) =>
		{
			this.sat_factura_info_list = response.sat_factura.data.map((i) => this.getType(i));

			this.setPages(this.sat_factura_search.page, response.sat_factura.total);
			this.is_loading = false;
		});
	}

	getType(sat_factura: Sat_Factura): CSatFacturaInfo
	{

		let link:(string|number)[] = [];

		if( sat_factura.type == 'NORMAL' )
			link= ['/view-order', sat_factura.order_id];

		if( sat_factura.type == 'COMPLEMENTO_PAGO' )
			link = ['/view-payment', sat_factura.payment_id];


		let name_type = 'Desconocido';

		if( sat_factura.type == 'NORMAL' )
		{
			name_type = 'Facturación';
		}

		if( sat_factura.type == 'COMPLEMENTO_PAGO' )
		{
			name_type = 'Factura de pago';
		}

		let sat_cancelled = sat_factura.cancelado_por_sat == 'NO' ? 'Activo' : 'Cancelado';
		let system_status = sat_factura.system_cancelled_timestamp == null ? 'Activo' : 'Cancelado';

		return {
			...sat_factura,
			name_type : name_type,
			sat_cancelled : sat_cancelled,
			link : link,
			system_status: system_status
		};
	}

	getPdfUrl(sat_factura: Sat_Factura)
	{
		return this.rest.getApiUrl()+'/getFacturaPdf.php?sat_factura_id='+sat_factura.id;
	}

	checarFactura(sat_factura: Sat_Factura)
	{
		this.is_loading = true;
		this.modal_UUID = sat_factura.uuid;
		this.modal_factura_id = sat_factura.id;
		let auth_header = this.rest.getSessionHeaders().get('Authorization') || '';

		fetch(this.rest.getApiUrl()+'/checar_factura.php?sat_factura_id='+sat_factura.id,
		{headers: {Authorization: auth_header}} )
		.then(response => response.json())
		.then(data => 
		{
			console.log(data);

			if (data.estado) 
			{
				this.is_loading = false;
				this.modal_response = data.estado;


				this.show_checar_factura = true;
			}
			else
			{
				this.showError('Error al revisar el estado de la factura');
			}

		})
		.catch(error => 
		{
			console.error('Error:', error);
		});
	}

	resendFactura(evt:Event)
	{
		// console.log("HOLA SI JALO LA FUNCIÓN");
	}

	replayFactura(sat_factura:Sat_Factura)
	{
		this.is_loading = true;

		this.sink = this.rest.replayFactura( sat_factura.id )
		.subscribe((sat_factura2)=>
		{
			//sat_factura = this.getType(sat_factura);
			sat_factura.uuid = sat_factura2.uuid;

		},(error)=>this.showError(error));
	}
}
