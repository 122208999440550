<h1>Agregar comex client</h1>
<div class="p-3">
	<form (submit)="save($event)" ngNativeValidate>
		<div class="row">
			<div class="col-6 form-group">
				<label>Número Tarjeta - usuario {{client_user_id}}</label>
				<input type="text" name="loginName" [(ngModel)]="comex_client.loginName" class="form-control" required>
			</div>
			<!--div class="col-6 form-group">
				<label>User Type</label>
				<input type="text" name="userType" [(ngModel)]="userType">
			</div-->

			<!--div class="col-6 form-group">
				<label>Login Location</label>
				<input type="text" name="loginLocation" [(ngModel)]="loginLocation">
			</div-->

			<div class="col-6 form-group">
				<label>Nombre</label>
				<input type="text" name="nombre" [(ngModel)]="comex_client.nombre" class="form-control" class="form-control" required>
			</div>

			<!--div class="col-6 form-group">
				<label>Password</label>
				<input type="password" name="password" [(ngModel)]="password">
			</div-->

			<div class="col-6 form-group">
				<label>Apellido Paterno</label>
				<input type="text" name="apellidoPaterno" [(ngModel)]="comex_client.apellidoPaterno" class="form-control" required>
			</div>

			<div class="col-6 form-group">
				<label>Apellido Materno</label>
				<input type="text" name="apellidoMaterno" [(ngModel)]="comex_client.apellidoMaterno" class="form-control" required>
			</div>

			<div class="col-6 form-group">
				<label>Estado Civil</label>
				<select  name="estadoCivil" [(ngModel)]="comex_client.estadoCivil" class="form-control" required>
					<option value="1">Soltero</option>
					<option value="2">Casado</option>
					<option value="3">Divorciado</option>
					<option value="4">Viudo</option>
					<option value="5">Unión Libre</option>
				</select>
			</div>

			<div class="col-6 form-group">
				<label>Email</label>
				<input type="email" name="eMail" [(ngModel)]="comex_client.eMail" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>País</label>
				<select  name="pais" [(ngModel)]="comex_client.pais" class="form-control" required>
					<option value="52">México</option>
					<option value="503">El Salvador</option>
				</select>
			</div>

			<div class="col-6 form-group">
				<label>Sexo</label>
				<select  name="sexo" [(ngModel)]="comex_client.sexo" class="form-control" required>
					<option value="M">Masculino</option>
					<option value="F">Femenino</option>
				</select>
			</div>

			<div class="col-6 form-group">
				<label>Fecha de Nacimiento</label>
				<input type="date" name="fechaNacimiento" [(ngModel)]="comex_client.fechaNacimiento" class="form-control" required>
			</div>

			<div class="col-6 form-group">
				<label>Teléfono Casa</label>
				<input type="tel" name="telefonoCasa" [(ngModel)]="comex_client.telefonoCasa" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Teléfono Oficina</label>
				<input type="tel" name="telefonoOficina" [(ngModel)]="comex_client.telefonoOficina" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Celular</label>
				<input type="tel" name="celular" [(ngModel)]="comex_client.celular" class="form-control" required>
			</div>

			<div class="col-6 form-group">
				<label>Calle</label>
				<input type="text" name="calle" [(ngModel)]="comex_client.calle" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Número Exterior</label>
				<input type="text" name="numeroExterior" [(ngModel)]="comex_client.numeroExterior" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Número Interior</label>
				<input type="text" name="numeroInterior" [(ngModel)]="comex_client.numeroInterior" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Colonia</label>
				<input type="text" name="colonia" [(ngModel)]="comex_client.colonia" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Municipio</label>
				<input type="text" name="municipio" [(ngModel)]="comex_client.municipio" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Estado</label>
				<input type="text" name="estado" [(ngModel)]="comex_client.estado" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Ciudad</label>
				<input type="text" name="ciudad" [(ngModel)]="comex_client.ciudad" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Código Postal</label>
				<input type="text" name="codigoPostal" [(ngModel)]="comex_client.codigoPostal" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Titulo posición</label>
				<input type="text" name="positionTitle" [(ngModel)]="comex_client.positionTitle" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Ocupación</label>
				<select name="ocupacion" [(ngModel)]="comex_client.ocupacion" class="form-control" required>
					<option value="">Seleccionar</option>
					<option value="1">Pintor / impermeabilizador (OFICIO)</option>
					<option value="2">Carpintero / barnizador (OFICIO)</option>
					<option value="3">Herrero (OFICIO)</option>
					<option value="4">Hojalatero (OFICIO)</option>
					<option value="5">Contratista (PROFESIONAL)</option>
					<option value="6">Arquitecto (PROFESIONAL)</option>
					<option value="7">Diseñador / interiorista (PROFESIONAL)</option>
					<option value="8">Mantenimiento (PROFESIONAL)</option>
					<option value="9">Jefe / Jefa de familia (PARTICULAR)</option>
					<option value="10">Soltero / estudiante (PARTICULAR)</option>
					<option value="11">Turismo / Hoteleria (EMPRESA)</option>
					<option value="12">Restaurante (EMPRESA)</option>
					<option value="13">Empresa industrial (EMPRESA)</option>
					<option value="14">Empresa Construccion (EMPRESA)</option>
					<option value="15">Local comercial (EMPRESA)</option>
					<option value="16">Empresa (EMPRESA)</option>
					<option value="17">Hospital (EMPRESA)</option>
					<option value="18">Gasolinera (EMPRESA)</option>
					<option value="19">Escuela (EMPRESA)</option>
				</select>
			</div>

			<div class="col-6 form-group">
				<label>Otra Ocupación</label>
				<select name="otraOcupacion" [(ngModel)]="comex_client.otraOcupacion" class="form-control">
					<option value="">Seleccionar</option>
					<option value="1">Pintor / impermeabilizador (OFICIO)</option>
					<option value="2">Carpintero / barnizador (OFICIO)</option>
					<option value="3">Herrero (OFICIO)</option>
					<option value="4">Hojalatero (OFICIO)</option>
					<option value="5">Contratista (PROFESIONAL)</option>
					<option value="6">Arquitecto (PROFESIONAL)</option>
					<option value="7">Diseñador / interiorista (PROFESIONAL)</option>
					<option value="8">Mantenimiento (PROFESIONAL)</option>
					<option value="9">Jefe / Jefa de familia (PARTICULAR)</option>
					<option value="10">Soltero / estudiante (PARTICULAR)</option>
					<option value="11">Turismo / Hoteleria (EMPRESA)</option>
					<option value="12">Restaurante (EMPRESA)</option>
					<option value="13">Empresa industrial (EMPRESA)</option>
					<option value="14">Empresa Construccion (EMPRESA)</option>
					<option value="15">Local comercial (EMPRESA)</option>
					<option value="16">Empresa (EMPRESA)</option>
					<option value="17">Hospital (EMPRESA)</option>
					<option value="18">Gasolinera (EMPRESA)</option>
					<option value="19">Escuela (EMPRESA)</option>
				</select>
			</div>

			<div class="col-6 form-group">
				<label>Compañía</label>
				<input type="text" name="compania" [(ngModel)]="comex_client.compania" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Llave de activación</label>
				<input type="text" name="activationKey" [(ngModel)]="comex_client.activationKey" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>External ID</label>
				<input type="text" name="externalId" [(ngModel)]="comex_client.externalId" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Contacto Teléfono</label>
				<input type="tel" name="contactoTel" [(ngModel)]="comex_client.contactoTel" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Contacto Email</label>
				<input type="email" name="contactoMail" [(ngModel)]="comex_client.contactoMail" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Contacto Dirección</label>
				<input type="text" name="contactoDireccion" [(ngModel)]="comex_client.contactoDireccion" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Operatel</label>
				<select name="Operatel" [(ngModel)]="comex_client.Operatel" class="form-control">
					<option value="1">Telcel</option>
					<option value="2">Movistar</option>
					<option value="3">IUSACEL</option>
					<option value="4">NEXTEL</option>
					<option value="5">UNEFON</option>
					<option value="6">VIRGIN</option>
				</select>
			</div>

			<div class="col-6 form-group">
				<label>RFC</label>
				<input type="text" name="rfc" [(ngModel)]="comex_client.rfc" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>&nbsp;</label>
				<div>
					<button type="submit" class="btn btn-primary">Guardar</button>
				</div>
			</div>

		</div>
	</form>
</div>
