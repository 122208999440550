import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { Utils } from 'src/app/classes/Utils';
import { OrderInfo, PaymentInfo } from 'src/app/models/models';
import { Billing_Data, Payment, Store, Store_Sale_Report } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

interface BoxPaymentEntries
{
	box_amount: number;
	payment_entries: PaymentEntry[];
}

interface Resume
{
	name:string;
	qty:number;
	total: number;
}

interface PaymentEntry
{
	currency_id:string;
	amount:number;
	payment_method: string;
	name:string;
}

interface COrderInfo extends OrderInfo
{
	paid_at_closing: number;
	credit_at_closing: number;
}

@Component({
	selector: 'app-view-sales-report',
	templateUrl: './view-sales-report.component.html',
	styleUrls: ['./view-sales-report.component.css']
})
export class ViewSalesReportComponent extends BaseComponent
{
	all_payment_entries:PaymentEntry[] = [];
	amount_paid: number = 0;
	big_total: number = 0;
	billing_data:Billing_Data = GetEmpty.billing_data();
	box_amount: number = 0;
	credit: number = 0;
	discounts: number = 0;
	ieps: number = 0;
	order_info_list: COrderInfo[] = [];
	other_payment_entries:PaymentEntry[] = [];
	payment_entries:PaymentEntry[] = [];
	payment_info_list: PaymentInfo[] = [];
	store: Store = GetEmpty.store();
	store_sale_report: Store_Sale_Report | null = null ;
	subtotal:number = 0;
	tax:number = 0;
	total:number = 0;
	total_abonos: number = 0;
	total_discounts: number = 0;
	total_i_ventas: number = 0;
	total_order_discount: number = 0;
	total_credit: number = 0;
	count_credit: number = 0;

	tax_resume_list: Resume[] = [];
	category_resume_list: Resume[] = [];
	user_resume_list:Resume[] = [];

	total_usd_mxn:number = 0;
	ventas_usd_mxn:number = 0;
	abonos_usd_mxn:number = 0;

	grand_total: number = 0;
	other_payments: PaymentInfo[] = [];
	has_hades:boolean = false;

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((param_map)=>
			{
				if( param_map.has('h') || this.rest.has_ares )
				{
					this.has_hades = true;
				}
				else
				{
					this.has_hades = false;
				}

				return this.rest.store_sale_report.get( param_map.get('id') )
			}),
			mergeMap((ssr:Store_Sale_Report)=>
			{
				let order_ids = ssr.order_ids.split(',')
				let ares_order_ids = [];

				if( this.has_hades )
				{
					ares_order_ids = ssr.ares_order_ids.split(',').filter(x=>x!='');
				}

				let all_order_ids = order_ids.concat( ares_order_ids );

				let payment_search ={
					eq: { store_id: ssr.store_id, type:'income', status:'ACTIVE' },
					ge: { created: ssr.start_timestamp },
					le: { created: ssr.end_timestamp },
					limit: 999999,
					search_extra:{ hades: this.has_hades ? 1 : null }
				} as Partial<SearchObject<Payment>>;

				let order_search ={
					csv:{ id: all_order_ids },
					limit:99999,
					search_extra:{ hades: this.has_hades ? 1 : null }
				};

				return forkJoin
				({
					billing_data: this.rest.getStore( ssr.store_id ).pipe
					(
						mergeMap((response)=>
						{
							this.store = response;
							if( this.store.default_billing_data_id )
							{
								return this.rest.billing_data.get( response.default_billing_data_id );
							}
							return of( GetEmpty.billing_data() );
						})
					),
					store_sale_report: of( ssr ),
					orders: this.rest.order_info.searchAsPost( order_search ).pipe
					(
						mergeMap((response)=>
						{
							let data = response.data.filter(oi=>
							{
								if( oi.order.status == 'CANCELLED' )
								{
									console.log(oi.order.status, oi.order.cancellation_timestamp, ssr.start_timestamp);
								}
								if( oi.order.status == 'CANCELLED' && oi.order.cancellation_timestamp < ssr.start_timestamp )
								{
									return false;
								}
								return true;
							});

							response.total = data.length;
							response.data = data;
							return of(response);
						})
					),
					//payment: this.rest.payment_info.search
					//({
					//	le: { created: ssr.end_timestamp },
					//	search_extra:{ order_id: order_ids.join(',') },
					//	limit: 99999
					//}),
					other_payments: this.rest.payment_info.search(payment_search),
				})
			}),
			//mergeMap((response)=>
			//{
			//	let order_ids = this.getOrderIdsFromPaymentList( response.other_payments.data );
			//	return forkJoin
			//	({
			//		orders: this.rest.order_info.searchAsPost( order_search ),
			//		billing_data: of(response.billing_data),
			//		other_payments: of(response.other_payments),
			//		store_sale_report: of(response.store_sale_report),
			//	});
			//})
		)
		.subscribe((response)=>
		{
			this.billing_data = response.billing_data;
			this.store_sale_report = response.store_sale_report;

			let order_ids_str = response.store_sale_report.order_ids;

			if( this.has_hades)
			{
				if( order_ids_str.length && response.store_sale_report.ares_order_ids.trim().length )
				{
					order_ids_str += ',';
				}
				order_ids_str += response.store_sale_report.ares_order_ids;
			}

			let order_ids = order_ids_str.split(',').map(id=>parseInt(id));

			this.payment_info_list = this.getSalesPayments(response.other_payments.data, order_ids);
			console.log('A',this.store_sale_report.ares_order_ids);
			console.log('NA',this.store_sale_report.order_ids);

			console.log('NA', this.store_sale_report.order_ids.split(',').length );
			console.log('A', this.store_sale_report.ares_order_ids.split(',').length );

			this.order_info_list = response.orders.data.map(order_info=>this.getCustomOrderInfo(order_info,response.other_payments.data));

			this.total_credit = this.order_info_list.reduce((p,oi)=>p+oi.credit_at_closing,0);
			this.count_credit = this.order_info_list.reduce((p,oi)=>p+(oi.credit_at_closing>0?1:0),0);

			//this.payment_info_list = this.getSalesPayments(response.other_payments.data, order_ids);

			let id_map:Map<number,boolean> = new Map();
			this.payment_info_list.forEach(p=>id_map.set(p.payment.id,true));

			console.log(this.payment_info_list.length);
			let other_payments = response.other_payments.data.filter(p=>!id_map.has(p.payment.id));

			console.log('Other payments length', other_payments.length);

			this.total		= this.order_info_list.reduce((p,oi)=>p+oi.order.total,0);
			this.subtotal	= this.order_info_list.reduce((p,oi)=>p+oi.order.subtotal,0);
			this.discounts = this.order_info_list.reduce((p,oi)=>p+oi.order.discount,0);
			this.tax = this.order_info_list.reduce((p,oi)=>p+oi.order.tax,0);
			this.ieps = this.order_info_list.reduce((p,oi)=>p+oi.order.sat_ieps,0);
			this.amount_paid = this.order_info_list.reduce((p,oi)=>p+oi.order.amount_paid,0);

			this.credit = this.order_info_list.reduce((p,oi)=>
			{
				let total = oi.order.total-oi.order.discount;
				return p+(total-oi.order.amount_paid);
			},0);

			let category_map:Map<string,Resume> = new Map();
			let tax_resume_map:Map<string,Resume> = new Map();
			let user_resume_map:Map<string,Resume> = new Map();

			let total_order_discount:number = 0;

			//Esto en teoria esta mal tenemos que sumar los Normales y
			//Restar los que regresan.

			let total_credit = 0;
			let total_discount = 0;

			let credit_labels = [];

			for(let oi of this.order_info_list)
			{
				if( oi.credit_at_closing )
				{
					credit_labels.push({ order_id: oi.order.id, credit_at_closing: oi.credit_at_closing });
				}
				total_credit += (oi.order.total-oi.order.discount)-oi.order.amount_paid;

				for(let ii of oi.items )
				{
					if( ii.order_item.total && ii.order_item.type == 'NORMAL')
					{
						let name = ii.category == null ? 'S/C' : ii.category.name;

						let cr = category_map.get( name );

						if( cr == null )
						{
							cr = {
								name: name,
								qty: 0,
								total: 0
							};
							category_map.set( cr.name, cr );
						}

						cr.total += ii.order_item.total;
						cr.qty	+= ii.order_item.qty;

						let tr_name = ii.item.applicable_tax == 'EXEMPT' ? 'IVA al 0%' : 'IVA '+oi.order.tax_percent+'%';
						let tr:Resume | null = tax_resume_map.get( tr_name ) || null;

						if( tr == null )
						{
							tr = { name :tr_name, qty:0, total:0 };
							tax_resume_map.set( tr_name, tr);
						}

						tr.qty += ii.order_item.qty;
						tr.total += ii.order_item.tax;

					}
				}

				let tr2_name = oi.order.sat_ieps ? 'IEPS %' : 'IEPS al 0%';
				let tr2:Resume | null = tax_resume_map.get( tr2_name ) || null;
				let ieps_qty = oi.items.reduce((p,ii)=>p+ii.order_item.ieps_value != 0 ? ii.order_item.qty : 0,0);

				if( ieps_qty )
				{
					if( ieps_qty && tr2 == null )
					{
						console.log('Adding IEPS to tax resume');
						tr2 = { name :tr2_name, qty:0, total:0 };
						tax_resume_map.set( tr2_name, tr2);
					}
					tr2.qty += ieps_qty;
					tr2.total += oi.order.sat_ieps;
				}
			}

			credit_labels.sort((a,b)=>a.order_id>b.order_id?1:-1);
			//console.table(credit_labels);

			let sort_resume = (a,b)=>a.name>b.name?1:-1;

			let trl = Array.from(tax_resume_map.values());
			trl.sort(sort_resume);

			let crl =	Array.from(category_map.values());
			crl.sort(sort_resume);

			this.tax_resume_list = trl;
			this.category_resume_list = crl;
			this.box_amount = this.total- this.discounts;

			this.payment_entries	= [
				{
					name:'Pesos',
					currency_id: 'MXN',
					payment_method: 'CASH',
					amount: 0
				}
				,{
					name:'Dolares',
					currency_id: 'USD',
					payment_method: 'CASH',
					amount: 0
				}
				,{
					name:'T.Credito',
					currency_id: 'MXN',
					payment_method: 'CREDIT_CARD',
					amount: 0
				}
				,{
					name:'T.Debito',
					currency_id: 'MXN',
					payment_method: 'DEBIT_CARD',
					amount: 0
				}
			];

			let box = 0;

			for(let pi of this.payment_info_list)
			{
				let user = pi.created_by_user.name;
				//let user_name =

				for(let mi of pi.movements)
				{
					for(let bmo of mi.bank_movement_orders)
					{
						let found = this.order_info_list.find((oi)=>oi.order.id == bmo.order_id );
						if( found )
						{
							let found_entry = this.payment_entries.find((e)=>{
								return e.currency_id == bmo.currency_id &&
									e.name == this.getTransactionName( mi.bank_movement.transaction_type, bmo.currency_id );
							});

							if(! found_entry )
							{
								found_entry = {
									currency_id:bmo.currency_id,
									amount: 0,
									payment_method: mi.bank_movement.transaction_type,
									name: this.getTransactionName( mi.bank_movement.transaction_type,bmo.currency_id )
								}
								this.payment_entries.push( found_entry );
							}

							found_entry.amount += bmo.currency_amount;

							if( mi.bank_movement.transaction_type == 'CASH' )
							{
								box += bmo.amount;
							}
						}
					}
				}
			}
			this.box_amount = box;
			console.log('Otehr payments',other_payments);
			//console.table(other_payments.map((p)=>
			//{
			//	return {
			//	'bmo_id':p.movements[0].bank_movement_orders[0].id,
			//	'payment_id':p.payment.id,
			//	'bmo_cantidad':p.movements[0].bank_movement_orders[0].amount,
			//	'transaction_type':p.movements[0].bank_movement.transaction_type,
			//	'amount':p.payment.payment_amount,
			//	'currency_id':p.movements[0].bank_movement.currency_id
			//	}
			//}));

			this.other_payment_entries = this.getPaymentEntries( other_payments ).payment_entries;

			let merged_payments = other_payments.concat( this.payment_info_list );

			this.all_payment_entries = this.getPaymentEntries( merged_payments ).payment_entries;

			let b_total = 0;

			this.total_usd_mxn = this.getPagosUSDINMXN(merged_payments);
			this.ventas_usd_mxn = this.getPagosUSDINMXN(this.payment_info_list);
			this.abonos_usd_mxn = this.getPagosUSDINMXN( other_payments);

			this.total_abonos = this.getTotalPayments( other_payments );
			this.big_total = this.getTotalPayments( merged_payments );
			this.total_i_ventas = this.getTotalPayments( this.payment_info_list );

			console.log('cantidad de otros pagos', other_payments.length);
			console.log('cantidad de pagos ventas', this.payment_info_list.length);
			console.log('cantidad de pagos totales', merged_payments.length);
			this.other_payments = other_payments;
		});
	}

	getSalesPayments(payment_info_list:PaymentInfo[], order_ids:number[]):PaymentInfo[]
	{
		let result:PaymentInfo[] = [];

		for(let payment_info of payment_info_list)
		{
			dance:
			for(let bm of payment_info.movements)
			{
				for(let bmo of bm.bank_movement_orders)
				{
					if( order_ids.includes(bmo.order_id) )
					{
						result.push(payment_info);
						break dance;
					}
					else
					{
						console.log('No se encuentra el payment_id:'+payment_info.payment.id+' order_id:'+bmo.order_id);
					}
				}
			}
		}

		return result;
	}

	getPaymentEntries(payment_info_list:PaymentInfo[]):BoxPaymentEntries
	{
		let payment_entries	= [
			{
				name:'Pesos',
				currency_id: 'MXN',
				payment_method: 'CASH',
				amount: 0
			}
			,{
				name:'Dolares',
				currency_id: 'USD',
				payment_method: 'CASH',
				amount: 0
			}
			,{
				name:'T.Credito',
				currency_id: 'MXN',
				payment_method: 'CREDIT_CARD',
				amount: 0
			}
			,{
				name:'T.Debito',
				currency_id: 'MXN',
				payment_method: 'DEBIT_CARD',
				amount: 0
			}
		];

		let box = 0;

		for(let pi of payment_info_list)
		{
			let user = pi.created_by_user.name;

			for(let mi of pi.movements)
			{
				for(let bmo of mi.bank_movement_orders)
				{
					let found_entry = payment_entries.find((e)=>
					{
						return e.currency_id == bmo.currency_id &&
							e.name == this.getTransactionName( mi.bank_movement.transaction_type, bmo.currency_id );
					});

					if(! found_entry )
					{
						found_entry = {
							currency_id:bmo.currency_id,
							amount: 0,
							payment_method: mi.bank_movement.transaction_type,
							name: this.getTransactionName( mi.bank_movement.transaction_type,bmo.currency_id )
						}
						payment_entries.push( found_entry );
					}

					found_entry.amount += bmo.currency_amount;

					box += bmo.amount;
				}
			}
		}

		return {
			box_amount: box,
			payment_entries: payment_entries
		};
	}

	getCustomOrderInfo(order_info:OrderInfo, payment_info_list:PaymentInfo[]):COrderInfo
	{
		let paid_at_closing = this.getAmountPaidForOrder( payment_info_list, order_info);
		let credit_at_closing = (order_info.order.total-order_info.order.discount)-paid_at_closing;

		return {...order_info, paid_at_closing, credit_at_closing}
	}

	getTransactionName(transaction_type:string, currency_id:string)
	{
		if( transaction_type == 'CASH' && currency_id == 'MXN' )
			return 'Pesos';

		if( transaction_type == 'CASH' && currency_id == 'USD' )
			return 'Dolares';

		if( transaction_type == 'CREDIT_CARD')
			return 'T.Credito';

		if( transaction_type == 'DEBIT_CARD' )
			return 'T.Debito';

		if( transaction_type == 'CHECK' )
			return 'Cheque';

		if( transaction_type == 'TRANSFER' )
			return 'Transferencia';

		if( transaction_type == 'PAYPAL' )
			return 'PAYPAL';

		return 'Otro';
	}

	getAmountPaidForOrder(payment_info_list:PaymentInfo[], order_info:OrderInfo):number
	{
		let amount = 0;

		for(let payment_info of payment_info_list)
		{
			for(let bm of payment_info.movements)
			{
				for(let bmo of bm.bank_movement_orders)
				{
					if( bmo.order_id == order_info.order.id )
					{
						amount += bmo.amount;
					}
				}
			}
		}
		return amount;
	}

	getTotalPayments(payment_info_list:PaymentInfo[]):number
	{
		let sum = 0;

		for(let payment_info of payment_info_list)
		{
			for(let bank_movement of payment_info.movements)
			{
				for(let bmo of bank_movement.bank_movement_orders)
				{
					sum += bmo.amount;
				}
			}
		}
		return sum;
	}

	getPagosUSDINMXN(payment_info_list:PaymentInfo[]):number
	{
		let sum = 0;

		for(let payment_info of payment_info_list)
		{
			for(let bank_movement of payment_info.movements)
			{
				if( bank_movement.bank_movement.currency_id != 'USD' )
				{
					continue;
				}

				for(let bmo of bank_movement.bank_movement_orders)
				{
					sum += bmo.amount;
				}
			}
		}
		return sum;
	}

	print()
	{
		window.print();
	}

	gotoPaymentAbonos()
	{
		let p_ids = this.other_payments.map(p=>p.payment.id);
		if(p_ids.length ==0 )
		{
			this.showError('No hay pagos para mostrar');
			return;
		}
		
		let queryParams = 
		{
			'ge.created': '2000-01-01 00:00:00',
			'csv.id': p_ids.join(',')
		};

		this.router.navigate(['/list-payment'],{queryParams});
	}

	gotoOrderAbonos()
	{
		let order_ids = this.getOrderIdsFromPaymentList(this.other_payments);
		let start = Utils.getUTCMysqlStringFromDate( this.store_sale_report.start_timestamp );
		let end = Utils.getUTCMysqlStringFromDate( this.store_sale_report.end_timestamp );

		if(order_ids.length ==0 )
		{
			this.showError('No hay ordenes para mostrar');
			return;
		}

		let queryParams ={
			'search_extra.start_timestamp': '2000-01-01 00:00:00',
			'csv.id':order_ids.join(',')
		};

		this.router.navigate(['/list-order'],{queryParams});
	}

	gotoOrderCreditos()
	{
		let credit_order_ids = this.order_info_list.filter(oi => oi.credit_at_closing > 0).map(oi => oi.order.id);
		let start = Utils.getUTCMysqlStringFromDate(this.store_sale_report.start_timestamp);
		let end = Utils.getUTCMysqlStringFromDate(this.store_sale_report.end_timestamp);

		if (credit_order_ids.length === 0)
		{
			this.showError('No hay órdenes de crédito para mostrar');
			return;
		}

		let queryParams = {
			'search_extra.start_timestamp': start,
			'search_extra.end_timestamp': end,
			'csv.id': credit_order_ids.join(',')
		};

		this.router.navigate(['/list-order'], { queryParams });
	}

	getOrderIdsFromPaymentList(payment_info_list:PaymentInfo[]):number[]
	{
		let order_ids:number[] = [];

		for(let pi of payment_info_list)
		{
			for(let mi of pi.movements)
			{
				for(let bmo of mi.bank_movement_orders)
				{
					order_ids.push( bmo.order_id );
				}
			}
		};

		return order_ids;
	}
}
