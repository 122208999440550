import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { Attribute } from 'src/app/models/RestModels';
import { Rest } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-save-attribute',
	templateUrl: './save-attribute.component.html',
	styleUrls: ['./save-attribute.component.css']
})
export class SaveAttributeComponent extends BaseComponent implements OnInit
{
	attribute: Attribute = GetEmpty.attribute();

	rest_attribute:Rest<Attribute,Attribute> = this.rest.initRest('attribute');

	ngOnInit(): void
	{
		this.sink = this.route.paramMap
		.pipe
		(
			mergeMap((param_map:ParamMap)=>
			{
				this.is_loading = true;

				if( param_map.has('id') )
				{
					return this.rest_attribute.get(param_map.get('id'));
				}

				return of( GetEmpty.attribute() );
			})
		)
		.subscribe
		({
			next:(attribute:Attribute)=>
			{
				this.is_loading = false;
				this.attribute = attribute;
			},
			error:(error)=>
			{
				this.showError( error )
			}
		});
	}

	save()
	{
		if( this.is_loading ) return;

		//this.onSubscritionSuccess(this.rest_attribute.create(this.attribute),(response)=>
		//{
		//	this.router.navigate(['/list-attribute']);
		//});


		this.sink = this.rest_attribute.create(this.attribute).subscribe
		({
			next:(response)=>
			{
				this.is_loading = false;
				this.router.navigate(['/list-attribute']);
			},
			error:(error)=>
			{
				this.showError( error )
			}
		});
	}
}
